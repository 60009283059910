import { useTranslation } from 'react-i18next';
import { getImageDefault } from 'utils';
import { GAMES } from 'utils/constants';
import style from './CardTournaments.module.css';

export default function CardDuration({ image, gameDuration, game }) {
  const { t } = useTranslation('app');
  return (
    <div
      className={style.cardLarge}
      style={{background: `#00b5ad url(${image ? image : getImageDefault(GAMES, game)}) center`}}
    >
      <div className={style.cardBottom}>
        {`${t('tournament.tournamentDuration')}: `}<span>{`${gameDuration} ${gameDuration > 1 ? t('tournament.hours') : t('tournament.hour')}`}</span>
      </div>
    </div>
  )
}