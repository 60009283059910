import { useState } from 'react';
import { Segment, Button, Image } from 'semantic-ui-react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export default function DropzoneComponent({
  acceptFiles, maxSize, multiple, setValue, name
}) {
  const { t } = useTranslation('app');
  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  return (
    <Dropzone
      onDrop={acceptedFiles => {
        setFiles(acceptedFiles);
        setValue(name, acceptedFiles);
      }}
      accept={acceptFiles || ""}
      maxSize={maxSize || 20971520}
      multiple={multiple || false}
    >
      {({
        getRootProps,
        getInputProps,
        isDragReject
      }) => {
        const fileNotAllowed = isDragReject && 'commons.fileTypeNotAllowed'
        return (
          <>
            <div {...getRootProps({className: "dropzone"})}>
              <input {...getInputProps()} />
              <Segment padded textAlign="center" vertical stacked>
                <p>{t('commons.dragAndDropImage')}</p>
                <Button primary type="button">{t('commons.clickHere')}</Button>

                {files.length > 0 && (
                  files.map(file => (
                    <Image
                      key={file.path}
                      style={{ height: 42, margin: '15px auto 0' }}
                      src={URL.createObjectURL(file)}
                    />
                  ))
                )}
              </Segment>
            </div>
            {fileNotAllowed && <p className="red">{t(fileNotAllowed)}</p>}
          </>
        );
      }}
    </Dropzone>
  )
}