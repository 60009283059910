import { Container, Segment, Grid, Header, Button, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { getImageDefault } from 'utils';
import { GAMES } from 'utils/constants';
import HeaderInternals from 'components/Layouts/Layout/Header';
import { useTranslation } from 'react-i18next';
import { timeFormat } from 'utils';
import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/es';
import style from './Subheader.module.css';

export default function SubheaderDetail({
  name, startDate, game, zone, organizers, setOpen, image, user, isPrivate, team
}) {
  const { t, lang } = useTranslation('app');
  moment.locale(lang);
  const dateTournament = moment(startDate.toDate())
  const dateNow = moment(Date.now());

  const platformLogo = (gameId) => {
    const logo = GAMES.filter(gm => gm.id === gameId);
    return logo[0].logo;
  }

  const currentTournament = () => {
    return dateTournament > dateNow
  }

  const isRegister = () => {
    if (team) {
      return team.find(t => t.teamCaptain === user.userId);
    }
    return null
  }

  return (
    <div style={{ background: `url(${image ? image : getImageDefault(GAMES, game) }) no-repeat center` }} className="bg-cover">
      <HeaderInternals height="270px" background="linear-gradient(137deg, rgba(48, 185, 209, 0.9) 20%, rgba(39, 154, 175, 0.9) 40%)" blur="blur(7px)">
        <Container style={{ paddingTop: 25 }}>
          <Segment basic>
            <Grid
              divided="vertically"
              stackable
              verticalAlign="middle"
            >
              <Grid.Row>
                <Grid.Column className="text-center-xs" width={12}>
                  <Header as='h2' color="black" inverted>
                    <Header.Subheader className={style.dateTournament}>
                      {`${t('tournament.thisTournament')} ${currentTournament() ? t('tournament.willStart') : t('tournament.started')}
                        ${startDate && dateTournament.from(dateNow)}
                        ${startDate && timeFormat(dateTournament)}
                        ${zone}
                      `}
                    </Header.Subheader>
                    <span className={style.nameTournament}>{name}</span>
                  </Header>
                  <br />
                  <Grid columns={2} stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3" color="black" inverted>
                          <Header.Subheader className={style.organizers}>
                            {t('tournament.organize')}
                          </Header.Subheader>
                          {organizers && organizers.length > 0 &&
                            (
                              organizers.map((org, index) => {
                                if (org.organizerImage) {
                                  return <a key={index.toString()} target="_blank" href={org.organizerUrl ? org.organizerUrl : ''} className={style.imgOrganizer}><img key={index.toString()} src={org.organizerImage} /></a>
                                } else {
                                  return <a key={index.toString()} target="_blank" href={org.organizerUrl ? org.organizerUrl : ''} className={style.link}><span key={index.toString()}>{org.organizerName ? `${org.organizerName}, ` : '-'}</span></a>
                                }
                              })
                            )
                          }
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as="h3" color="black" inverted>
                          <Header.Subheader className={style.organizers}>
                            {t('tournament.entry')}:
                          </Header.Subheader>
                          <span className={style.price}>
                            {isPrivate ? t('tournament.withConditions') : t('tournament.free')}
                          </span>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column textAlign="center" className="text-center-xs" width={4}>
                  <div className="text-center" className={style.containerLogo}>
                    {game && <Image src={platformLogo(game)} alt={game} />}
                  </div>
                  <br />
                  {user ? (
                    currentTournament() && isRegister() === null && (
                      <Button className="btn-primary btn-red" onClick={() => setOpen(true)}>
                        {t('tournament.signUpForThisTournament')}
                      </Button>
                    )
                  ) : (
                    <Link to="/login" className="ui button btn-primary btn-red">
                      {t('tournament.signUpForThisTournament')}
                    </Link>
                  )}
                  <br /><br />
                  <Link to="/app/tournaments/new" className={`${style.linkWhite} link-white light-white-color`}>
                    <b>{t('tournament.hostATournament')}</b>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </HeaderInternals>
    </div>
  )
}