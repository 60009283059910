import { Segment, Form, Button } from 'semantic-ui-react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import DropzoneComponent from 'components/DropzoneComponent';
import schema from './schemaParticipants';

export default function TabParticipants({
  onSubmit,
  activeTabIndex,
  setActiveTabIndex
}) {
  const { t } = useTranslation('app');

  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      sponsors: [{}],
      organizers: [{}]
    },
    resolver: yupResolver(schema)
  });

  const {
    fields: fieldsSponsors,
    append: appendSponsors,
    remove: removeSponsors
  } = useFieldArray({
    control,
    name: 'sponsors'
  });

  const {
    fields: fieldsOrganizers,
    append: appendOrganizers,
    remove: removeOrganizers
  } = useFieldArray({
    control,
    name: 'organizers'
  });

  return (
    <Form size="large" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
      <Segment textAlign="left" color="teal" stacked padded="very">

        {fieldsOrganizers.map((item, index) => (
          <Form.Group widths="equal" key={item.id}>
            <Form.Field>
              <label>{t('tournaments.form.organizerName')}</label>
              <input
                name={`organizers[${index}].organizerName`}
                placeholder={t('tournaments.form.organizerName')}
                defaultValue={item.organizerName}
                ref={register()}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('tournaments.form.organizerUrl')}</label>
              <input
                type="url"
                name={`organizers[${index}].organizerUrl`}
                placeholder="URL"
                defaultValue={item.organizeUrl}
                ref={register()}
              />
              <ErrorMessage
                errors={errors}
                name={`organizers[${index}].organizerUrl`}
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('tournaments.form.organizerImage')}</label>
              <Controller
                name={`organizers[${index}].organizerImage`}
                control={control}
                defaultValue=""
                render={() => (
                  <DropzoneComponent
                    multiple={false}
                    maxSize={20971520}
                    acceptFiles="image/*"
                    setValue={setValue}
                    name={`organizers[${index}].organizerImage`}
                  />
                )}
              />
            </Form.Field>
            <Button
              type="button"
              icon="close"
              color="red"
              size="small"
              style={{ height: 33 }}
              inverted
              onClick={() => removeOrganizers(index)}
            ></Button>
          </Form.Group>
        ))}
        <div className="text-center">
          <br />
          <Button
            type="button"
            content={t('tournaments.form.add')}
            size="medium"
            disabled={fieldsOrganizers.length > 3}
            onClick={() => appendOrganizers({})}
          />
        </div>

        <h3 className="text-center">{t('tournaments.form.sponsors.title')}</h3>
        {fieldsSponsors.map((item, index) => (
          <Form.Group widths="equal" key={item.id}>
            <Form.Field>
              <label>{t('tournaments.form.sponsors.fullName')}</label>
              <input
                style={{ width: '80%', marginRight: 5 }}
                name={`sponsors[${index}].fullName`}
                placeholder={t('tournaments.form.sponsors.fullName')}
                ref={register()}
                defaultValue={item.fullName}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('tournaments.form.sponsors.webPage')}</label>
              <input
                type="url"
                name={`sponsors[${index}].sponsorUrl`}
                placeholder="URL"
                defaultValue={item.sponsorUrl}
                ref={register()}
              />
              <ErrorMessage
                errors={errors}
                name={`sponsors[${index}].sponsorUrl`}
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('tournaments.form.sponsors.picture')}</label>
              <Controller
                name={`sponsors[${index}].sponsorImage`}
                control={control}
                defaultValue=""
                render={() => (
                  <DropzoneComponent
                    multiple={false}
                    maxSize={20971520}
                    acceptFiles="image/*"
                    setValue={setValue}
                    name={`sponsors[${index}].sponsorImage`}
                  />
                )}
              />
            </Form.Field>
            <Button
              type="button"
              icon="close"
              color="red"
              size="small"
              inverted
              style={{ height: 33 }}
              onClick={() => removeSponsors(index)}
            ></Button>
          </Form.Group>
        ))}

        <div className="text-center">
          <br />
          <Button
            type="button"
            content={t('tournaments.form.add')}
            size="medium"
            disabled={fieldsSponsors.length > 3}
            onClick={() => appendSponsors({})}
          />
        </div>
        <br />
        <Form.Group widths="3" style={{ alignItems: 'center' }}>
          <Form.Field inline>
            <label>{t('tournaments.form.private')}</label>
            <Controller
              control={control}
              name="isPrivate"
              defaultValue={false}
              render={(
                { onChange, onBlur, value },
              ) => (
                <input
                  type="checkbox"
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                />
              )}
            />
          </Form.Field>
        </Form.Group>

        <br /><br />
        <div className="text-center">
          <Button
            type="button"
            color="teal"
            content={t('tournaments.form.back')}
            icon="arrow left"
            size="large"
            labelPosition="left"
            onClick={() => setActiveTabIndex(activeTabIndex - 1)}
          />
          <Button
            color="teal"
            content={t('tournaments.form.submit')}
            icon="flag checkered"
            size="large"
            labelPosition="right"
          />
        </div> 
      </Segment>
    </Form>
  )
}