import { SIGNUP, LOGIN, LOGOUT, RESET_PASSWORD } from './constants';
import { message } from '../commons/actions';

export const signup = async (dispatch, firebase, values, history) => {
  dispatch({
    type: SIGNUP,
    session: { loading: true }
  });
  const { name, email, platform, username, password, socialMedia } = values;
  const data = {
    createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    name,
    email,
    platform,
    username,
    socialMedia
  }
  return new Promise((resolve, reject) => {
    firebase.auth
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        firebase.db
          .collection('users')
          .doc(auth.user.uid)
          .set({
            userId: auth.user.uid,
            ...data
          })
          .then((doc) => {
            dispatch({
              type: SIGNUP,
              session: {
                user: { ...data },
                isLogin: true,
                loading: false
              },
            });
            history.push('/');
          });
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: SIGNUP, session: { loading: false } });
        message(dispatch, error && error.message ? error.message : 'Ops ha ocurrido un error');
      });
  });
}

export const login = async (dispatch, firebase, values, history) => {
  dispatch({
    type: LOGIN,
    session: { loading: true }
  });
  const { email, password } = values;
  return new Promise((resolve, reject) => {
    firebase.auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        firebase.db
          .collection('users')
          .doc(auth.user.uid)
          .get()
          .then((doc) => {
            dispatch({
              type: LOGIN,
              session: {
                user: {
                  userId: auth.user.uid,
                  ...values
                },
                isLogin: true,
                loading: false
              },
            });
            history.push('/');
          })
          .catch((error) => {
            dispatch({ type: LOGIN, session: { user: null, isLogin: false, loading: false } });
            reject();
          });
      })
      .catch((error) => {
        reject();
        dispatch({ type: LOGIN, session: { user: null, isLogin: false, loading: false } });
        message(dispatch, error && error.message ? error.message : 'Ops ha ocurrido un error');
      });
  });
}

export const resetPassword = async (dispatch, firebase, values) => {
  dispatch({
    type: RESET_PASSWORD,
    session: { loading: true }
  });
  const { email } = values;
  
  return new Promise((resolve, reject) => {
    firebase.auth.sendPasswordResetEmail(email)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject();
        dispatch({ type: RESET_PASSWORD, session: { loading: false } });
        message(dispatch, error && error.message ? error.message : 'Ops ha ocurrido un error');
      });
  });
}

export const logout = async (dispatch, firebase, history) => {
  firebase.auth.signOut().then(() => {
    localStorage.clear();
    dispatch({
      type: LOGOUT,
      session: {
        user: null,
        loading: false,
        isLogin: false
      }
    });
    history.push('/login')
  })
}

export const editUser = async (dispatch, firebase, values, userId) => {
  return new Promise((resolve, reject) => {
    firebase.db
      .collection('users')
      .doc(userId)
      .update({
        ...values
      })
      .then(() => {
        resolve(true);
        message(dispatch, 'Guardado correctamente');
      })
      .catch(() => {
        reject(false);
        message(dispatch, 'Ops ha ocurrido un error');
      })
  });
}