import app_en from 'translations/en/app.json';
import app_es from 'translations/es/app.json';

export const configTranslations = {
  fallbackLng: 'es',
  interpolation: { escapeValue: false },
  resources: {
    en: {
      app: app_en
    },
    es: {
      app: app_es
    }
  }
}