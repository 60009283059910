import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import Signup from 'pages/Session/Signup';
import Login from 'pages/Session/Login';
import ResetPassword from 'pages/Session/ResetPassword';
import Layout from 'components/Layouts/Layout';
import Error from 'components/Error';
import Privacy from 'pages/Privacy';

import Profile from 'pages/Profile';
import EditProfile from 'pages/Profile/EditProfile';
import NewTournament from 'pages/Tournaments/New';
import Home from 'pages/Tournaments/Home';
import Tournaments from 'pages/Tournaments';
import TournamentDetail from 'pages/Tournaments/TournamentDetail';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="/">
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/tournaments/:id/:name" component={TournamentDetail} />
            <Route exact path="/tournaments" component={Tournaments} />
            <Route exact path="/app/tournaments/new" component={NewTournament} />
            <Route exact path="/app/tournaments/:id" component={TournamentDetail} />
            <Route exact path="/app/profile" component={Profile} />
            <Route exact path="/app/profile/edit" component={EditProfile} />
            <Route exact path="/privacy" component={Privacy} />
          </Switch>
        </Layout>
      </Route>
      <Route component={Error} />
    </Switch>
  </Router>
);

export default Routes;