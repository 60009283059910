import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { signup } from 'context/session/actions';
import { useStateValue } from 'context/store';
import { FirebaseContext } from 'server';
import { message as closeMessage } from 'context/commons/actions';

import FloatingMessage from 'components/FloatingMessage';
import Loading from 'components/Loading';
import LayoutOut from 'components/Layouts/LayoutOut';
import SignupForm from 'components/Forms/SignupForm';

const Signup = () => {
  const firebase = useContext(FirebaseContext);
  const [{ session: { loading }, commons: { message } }, dispatch] = useStateValue();
  const { t } = useTranslation('app');
  const history = useHistory();

  const onSubmit = async values => {
    await signup(dispatch, firebase, values, history);
  }

  return (
    <LayoutOut
      title={t('session.createYourUser')}
      icon="address card outline"
    >
      {loading && <Loading />}
      <SignupForm submit={onSubmit} />

      {message && (
        <FloatingMessage
          message={message}
          isOpen={!!message}
          close={() => closeMessage(dispatch, null)}
        />
      )}
    </LayoutOut>
  )
}

export default Signup;