import { useState, useContext, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { FirebaseContext } from 'server';
import { useStateValue } from 'context/store';
import { getDataUser, clearUser } from 'context/commons/actions';
import TopMenu from './TopMenu';
import Footer from './Footer';
import Loading from 'components/Loading';
import Warning from './Warning';

const Layout = ({ children, location }) => {
  const firebase = useContext(FirebaseContext);
  const [{ commons: { loadingUser, userData } }, dispatch] = useStateValue();
  const [user, setUser] = useState(null)
  const isLogin = localStorage.getItem('isLogin');

  useEffect(() => {
    firebase.isLogin().then(status => {
      return status ? setUser(firebase.auth.currentUser) : setUser(null);
    })
  }, [firebase.auth.currentUser, setUser])

  useEffect(() => {
    user && getDataUser(dispatch, firebase, user.uid);
    return () => clearUser(dispatch);
  }, [user])

  const loc = location.pathname.split('/')[1];

  if (!isLogin && loc === 'app') return <Redirect exact to="/login" />;

  return (
    <div>
      <TopMenu user={userData} />
      <div className="body-page">
        <Warning />
        {loadingUser && <Loading />}
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(Layout);