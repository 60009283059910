import { Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import PrivacyEs from './Texts/PrivacyEs';
import PrivacyEn from './Texts/PrivacyEn';
import style from './Privacy.module.css';

export default function Privary() {
  const { i18n } = useTranslation('app');
  return (
    <Container>
      <div className={style.content}>
        <h2 className={style.title}>
          GAMEFATHER 92
        </h2>
        {i18n.language === 'es'
          ? <PrivacyEs />
          : <PrivacyEn />
        }
      </div>
    </Container>
  )
}