import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyA7mHeKSDJ_aygwU4qjStOrxg-Ixt6IBAA',
  authDomain: 'gamefather92-7abe6.firebaseapp.com',
  projectId: 'gamefather92-7abe6',
  storageBucket: 'gamefather92-7abe6.appspot.com',
  messagingSenderId: '385448901570',
  appId: '1:385448901570:web:6c50c018d7d8f2024cf45b',
  measurementId: 'G-WNEPXKZBC7'
};

class Firebase {
  constructor() {
    !app.apps.length && app.initializeApp(config);
    this.db = app.firestore();
    this.firestore = app.firestore;
    this.auth = app.auth();
    this.functions = app.functions();
    //this.functions.useEmulator("localhost", 5001);
    this.storage = app.storage();
  }

  isLogin() {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged((user) => {
        user ? resolve({ status: true }) : reject({ status: false });
      });
    });
  }

}

export default Firebase;
