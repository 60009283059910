import style from '../Privacy.module.css';

export default function PrivacyEn() {
  return (
    <>
      <p className={style.subtitle}>
        PERSONAL INFORMATION PROTECTION, PRIVACY AND TOURNAMENT ORGANIZATION POLICY
      </p>

      <div>
        <h2 className={style.titleItems}>
          Introduction
        </h2>
        <p className={style.text}>
          92 Tournaments a platform created by GameFather92 is committed to respecting your privacy and
          recognizes your need for appropriate protection and management of any personally identifiable
          information (“Personal Information”) you share with us. 92 Tournaments has established this
          Privacy and Tournament Organization Policy to let you know what information wemay collectfrom
          you on the publicly available portion of our website https://www.92tournaments.com/ and through
          various other interactions with you, and how we may use and share that information. Please take a
          moment to review the terms of our Privacy and Tournament Organization Policy. By using the Site
          and/or providing us with Personal Information, you agree to accept the terms of our Privacy and
          Tournament Organization Policy. If you do not agree to the terms of this Privacy and Tournament
          Organization Policy, please do not use the Site or provide us with any Personal Information. This
          Privacy and Tournament Organization Policy applies to information that we collect on the Site and
          to information which we may collect by other “offline” means.
        </p>
      </div><br />

      <div>
        <h2 className={style.titleItems}>
          Privacy Policy
        </h2>
        <p className={style.text}>
          Protecting your private information is our priority. This Statement of Privacy applies to
          https://www.92tournaments.com/ and GameFather92 and governs data collection and usage. For
          the purposes of this Privacy and Tournament Organization Policy, unless otherwise noted, all
          references to GameFather92 include https://www.92tournaments.com/ and 92 Tournaments. The
          92 Tournaments website is a full-service platform that offers the opportunity to create and organize
          gaming tournaments from a wide variety of games and settings by third parties or organizations
          that wish to develop their personal non-professional or professional tourneys. By using the 92
          Tournaments website, you consent to the data practices described in this statement.
        </p>

        <h2 className={style.subtitleItems}>
          Collection of your Personal Information
        </h2>
        <p className={style.text}>
          In order to better provide you with products and services offered on our Site, 92 Tournaments may
          collect personally identifiable information, such as your:
        </p>
        <ul className={style.list}>
          <li>First and Last Name</li>
          <li>Mailing Address</li>
          <li>E-mail Address</li>
          <li>Phone Number</li>
          <li>Gaming Usernames from different platforms or games</li>
          <li>Birth date</li>
        </ul>
        <p className={style.text}>
          92 Tournaments may also collect anonymous demographic information, which is not unique to
          you, such as your:
        </p>
        <ul className={style.list}>
          <li>Age</li>
          <li>Gender</li>
          <li>Race</li>
        </ul>

        <p className={style.text}>
          We do not collect any personal information about you unless you voluntarily provide it to us.
          However, you may be required to provide certain personal information to us when you elect to use
          certain products or services available on the Site. These may include: (a) registering for an account
          on our Site; (b) entering a sweepstakes or contestsponsored by us or one of our partners; (c)signing
          up for special offers from selected third parties; (d) sending us an email message. To wit, we will
          use your information for, but not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional personal or non-personal
          information in the future.
        </p>
      </div><br /><br />

      <div>
        <h2 className={style.titleItems}>
          Use of your Personal Information
        </h2>
        <p className={style.text}>
          92 Tournaments collects and uses your personal information to operate its website(s) and deliver the
          services you have requested.
          <br /><br />
          92 Tournaments may also use your personally identifiable information to inform you of other
          products or services available from 92 Tournaments and its affiliates or sponsors.
        </p>

        <h2 className={style.subtitleItems}>
          Sharing Information with Third Parties
        </h2>
        <p className={style.text}>
          92 Tournaments does not sell, rent or lease its customer lists to third parties.
          <br /><br />
          92 Tournaments may share data with trusted partners to help perform statistical analysis, send you
          email or postal mail, provide customer support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide these services to 92
          Tournaments, and they are required to maintain the confidentiality of your information.
          <br /><br />
          92 Tournamentsmay disclose your personal information, without notice, if required to do so by law
          or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or
          comply with legal process served on 92 Tournaments or the site; (b) protect and defend the rights
          or property of 92 Tournaments; and/or (c) act under exigent circumstances to protect the personal
          safety of users of 92 Tournaments, or the public.
        </p>

        <h2 className={style.subtitleItems}>
          Tracking User Behavior
        </h2>
        <p className={style.text}>
          92 Tournaments may keep track of the websites and pages our users visit within 92 Tournaments,
          in order to determine what 92 Tournaments services are the most popular. This data is used to
          deliver customized content and advertising within 92 Tournaments to customers whose behavior
          indicates that they are interested in a particular subject area.
        </p>

        <h2 className={style.subtitleItems}>
          Automatically Collected Information
        </h2>
        <p className={style.text}>
          Information about your computer hardware and software may be automatically collected by 92
          Tournaments. This information can include: your IP address, browser type, domain names, access
          times and referring website addresses. This information is used for the operation of the service, to
          maintain quality of the service, and to provide general statistics regarding use of the 92
          Tournaments website.
        </p>

        <h2 className={style.subtitleItems}>
          Use of Cookies
        </h2>
        <p className={style.text}>
          The 92 Tournaments website may use "cookies" to help you personalize your online experience. A
          cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used
          to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and
          can only be read by a web server in the domain that issued the cookie to you.
          <br /><br />
          One of the primary purposes of cookies is to provide a convenience feature to save you time. The
          purpose of a cookie is to tell the Web server that you have returned to a specific page. For example,
          if you personalize 92 Tournaments pages, or register with 92 Tournamentssite orservices, a cookie
          helps 92 Tournamentsto recall your specific information on subsequent visits. This simplifies the
          process of recording your personal information, such as Username, Gaming User on each platform
          register, and so on. When you return to the same 92 Tournaments website, the information you previously provided can be retrieved, so you can easily use the 92 Tournaments features that
          you customized.
          <br /><br />
          You have the ability to accept or decline cookies. Most Web browsers automatically accept
          cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you
          choose to decline cookies, you may not be able to fully experience the interactive features of the
          92 Tournamentsservices or websites you visit.
        </p>

        <h2 className={style.subtitleItems}>
          Security of your Personal Information
        </h2>
        <p className={style.text}>
          92 Tournamentssecures your personal information from unauthorized access, use, or disclosure. 92
          Tournaments uses the following methods for this purpose:
        </p>
        <ul className={style.list}>
          <li>SSL Protocol</li>
        </ul>
        <p className={style.text}>
          When personal information (such as a credit card number) is transmitted to other websites, it is
          protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
          <br /><br />
          We strive to take appropriate security measures to protect against unauthorized access to or
          alteration of your personal information. Unfortunately, no data transmission over the Internet or
          any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect
          your personal information, you acknowledge that: (a) there are security and privacy limitations
          inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of
          any and all information and data exchanged between you and us through this Site cannot be
          guaranteed.
        </p>

        <h2 className={style.subtitleItems}>
          Right to Deletion
        </h2>
        <p className={style.text}>
          Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:        </p>
        <ul className={style.list}>
          <li>Delete your personal information from our records.</li>
          <li>Direct any service providers to delete your personal information from their records.</li>
        </ul>

        <p className={style.text}>
          Please note that we may not be able to comply with requests to delete your personal information
          if it is necessary to:
        </p>
        <ul className={style.list}>
          <li>
            Complete the transaction for which the personal information was collected, fulfill the terms
            of a written warranty or product recall conducted in accordance with federal law, provide
            a good or service requested by you, or reasonably anticipated within the context of our
            ongoing business relationship with you, or otherwise perform a contract between you and
            us.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
            activity; or prosecute those responsible for that activity.
          </li>
          <li>
            Debug to identify and repair errors that impair existing intended functionality.
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to exercise his or her right of
            free speech, or exercise another right provided for by law.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act.
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or statistical research in the public
            interest that adheres to all other applicable ethics and privacy laws, when our deletion of
            the information is likely to render impossible or seriously impair the achievement of such
            research, provided we have obtained your informed consent.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with your expectations based on
            your relationship with us.
          </li>
          <li>
            Comply with an existing legal obligation; or
            otherwise use your personal information, internally, in a lawful manner that is compatible
            with the context in which you provided the information.
          </li>
        </ul>

        <h2 className={style.subtitleItems}>
          Children Under Thirteen
        </h2>
        <p className={style.text}>
          92 Tournaments does not knowingly collect personally identifiable information from children
          under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian
          for permission to use this website.
        </p>

        <h2 className={style.subtitleItems}>
          E-mail Communications
        </h2>
        <p className={style.text}>
          From time to time, 92 Tournaments may contact you via email for the purpose of providing
          announcements, promotional offers, alerts, confirmations, surveys, and/or other general
          communication. In order to improve our Services, we may receive a notification when you open
          an email from 92 Tournaments or click on a link therein.
        </p>

        <h2 className={style.subtitleItems}>
          Governing Law
        </h2>
        <p className={style.text}>
          92 Tournaments makes no representation that this Privacy Policy and such practices comply with
          the laws of any other country. Visitors who use the Site and reside outside the United States do so
          on their own initiative and are responsible for compliance with local laws, if and to the extent that
          local laws are applicable. If you reside outside of the United States, by using our Site, you consent
          to the transfer and use of your information outside your country.
        </p>

        <h2 className={style.subtitleItems}>
          Changes to this Statement
        </h2>
        <p className={style.text}>
          92 Tournamentsreserves the right to change this Privacy Policy from time to time. We will notify
          you about significant changes in the way we treat personal information by sending a notice to the
          primary email address specified in your account, by placing a prominent notice on our site, and/or
          by updating any privacy information on this page. Your continued use of the Site and/or Services
          available through this Site after such modifications will constitute your: (a) acknowledgment of
          the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
        </p>

        <h2 className={style.subtitleItems}>
          Tournament Organization Policy:
        </h2>
        <p className={style.text}>
          As a member of 92 Tournaments platform you can freely organize and established all sort of
          tournaments from a wide variety of games such as Call Of Duty: Warzone, Valorant or Fortnite,
          but in no way this means that 92 Tournaments or GameFather92 takes any responsibility for the
          compliance of the commitment you as organizer take by organizing a tournament in the platform,
          you as organizer of a tournament accept without mistakes that it is your sole obligation as organizer
          to take full and whole responsibility for the details and establishments you decide to commit to by
          creating a tournament in the platform. 
          <br /><br />
          Also, if you are not organizing a tournament but you are acting as a member in one of them, you
          state by accepting this policy that you will leave GameFather92 or 92 Tournaments harmless of
          any action or reclamation against a tournament and you will direct or your complaints to the
          organizer or that specific tournament, as he/she/it is the solely responsible for the good
          performance and compliance of the requisites and awards established.
        </p>

        <h2 className={style.subtitleItems}>
          Awards or Prizes Policy:
        </h2>
        <p className={style.text}>
          GameFather92 or 92 Tournaments are in no way responsible for the prizes or awards that are
          established in any of the tournaments created in the website platform, 92 Tournaments and
          GameFather92 only serve as a creator of a web platform so gamers and organization can develop
          their tournaments with the prizes, they solely commit to comply. In no way the platform or
          GameFather92 is responsible for the non-compliance of this prizes or the established requisite of
          each tournament by each organizer. 
          <br /><br />
          As stated, 92 Tournaments and GameFather92 only serve as givers of a platform to make the
          creation of gaming tournaments easier but are not responsible for any award or prize reclamation
          or problem that may occur, this are a sole responsibility of each tournament’s organizer and
          sponsor if so. 
        </p>

        <h2 className={style.subtitleItems}>
          Contact Information
        </h2>
        <p className={style.text}>
          92 Tournaments welcomes your questions or comments regarding this Statement of Privacy. If you
          believe that 92 Tournaments has not adhered to this Statement, please contact 92 Tournaments at:
          <br />
          Email Address:
          <br />
          info@gamefather92.com
          <br /> <br /><br />
          Effective as of April 10, 2021.
        </p>
      </div>

    </>
  )
}