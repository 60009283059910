import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('commons.requiredField'),
  email: yup.string().required('commons.requiredField').email('commons.invalidEmail'),
  platform: yup.string().required('commons.selectAPlatform'),
  username: yup.string().required('commons.chooseAnOptionFromTheList'),
  password: yup.string().required('commons.requiredField').min(6, 'commons.minimumCharacters'),
  socialMedia: yup.object().shape({
    fbSocial: yup.string().url('commons.mustBeAvalidUrl'),
    twitchSocial: yup.string().url('commons.mustBeAvalidUrl'),
    ytSocial: yup.string().url('commons.mustBeAvalidUrl'),
    otherSocial: yup.string().url('commons.mustBeAvalidUrl')
  }),
  terms: yup.boolean().oneOf([true], 'commons.mustAcceptTheTermsAndConditions')
});

export const schemaEdit = yup.object().shape({
  name: yup.string().required('commons.requiredField'),
  platform: yup.string().required('commons.selectAPlatform'),
  username: yup.string().required('commons.chooseAnOptionFromTheList'),
  socialMedia: yup.object().shape({
    fbSocial: yup.string().url('commons.mustBeAvalidUrl'),
    twitchSocial: yup.string().url('commons.mustBeAvalidUrl'),
    ytSocial: yup.string().url('commons.mustBeAvalidUrl'),
    otherSocial: yup.string().url('commons.mustBeAvalidUrl')
  })
});
