import { useEffect, useContext } from 'react';
import { Container, Icon } from 'semantic-ui-react';
import { FirebaseContext } from 'server';
import { getPlayerInformation, clearPlayer } from 'context/playerData/actions';
import Loading from 'components/Loading';
import SubheaderProfile from './components/Subheader';
import SocialProfile from './components/Social';
import PersonalData from './components/PersonalData';
import { useStateValue } from 'context/store';
import style from './Profile.module.css';

const Profile = () => {
  const firebase = useContext(FirebaseContext);
  const [{
    playerData: { data, loadingPlayer },
    commons: { userData }
  }, dispatch] = useStateValue();

  const getPlayer = async () => await getPlayerInformation(dispatch, firebase, userData);

  useEffect(() => {
    userData && getPlayer();
    return () => clearPlayer(dispatch);
  }, [userData]);

  return (
    <>
      {loadingPlayer && <Loading />}
      {data && <SubheaderProfile data={data} /> }
      <div className={style.contentProfile} style={{ marginTop: data ? '' : '140px' }}>
        <div className={style.circle}>
          {userData ? (
            <div className={style.initialsName}>
              {userData.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}
            </div>
            ) : (
            <Icon name="user circle" size="massive" className={style.avatar} />
          )}
        </div>
        {userData && (
          <Container className={style.margin50}>
            <PersonalData {...userData} />
            <SocialProfile social={userData.socialMedia} />
          </Container>
        )}
      </div>
    </>
  )
}

export default Profile;