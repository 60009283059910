import { useTranslation } from 'react-i18next';
import style from './Awards.module.css';

export default function Awards({ awards }) {
  const { t } = useTranslation('app');
  return (
    <div className={style.awards}>
      <div className={style.awardsHeader}>
        <img src="/images/icons/trophy.svg" />
        <span>{t('tournament.awardsOfThisTournament')}</span>
      </div>
      <ul className={style.contentList}>
        <li>
          <span>1</span>
          <p>{awards.winner}</p>
        </li>
        {awards.top2 && <li>
          <span>2</span>
          <p>{awards.top2}</p>
        </li>}
        {awards.top3 && <li>
          <span>3</span>
          <p>{awards.top3}</p>
        </li>}
        {awards.top4 && <li>
          <span>4</span>
          <p>{awards.top4}</p>
        </li>}
        {awards.top5 && <li>
          <span>5</span>
          <p>{awards.top5}</p>
        </li>}
      </ul>
    </div>
  )
}