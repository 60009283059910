import { useEffect, useContext, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FirebaseContext } from 'server';
import { useStateValue } from 'context/store';
import { detailTournament, getTeam } from 'context/tournaments/actions';
import { FORMAT_BRACKETS, FORMAT_TOURNAMENT } from 'utils/constants';
import SubheaderDetail from '../components/Subheaders/SubheaderDetail';
import Detail from '../components/Detail';
import Sponsors from '../components/Detail/Sponsors';
import AddParticipants from 'components/Modals/Participants/Participants';
import FloatingMessage from 'components/FloatingMessage';
import { Bracket } from 'react-brackets';

export default function TournamentDetail() {
  const { t } = useTranslation('app');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [rounds, setRounds] = useState([]);
  const firebase = useContext(FirebaseContext);
  const [{tournaments: { detail, team }, commons: { userData }}, dispatch] = useStateValue();
  const { id } = useParams();
  
  useEffect(() => {
    detailTournament(firebase, dispatch, id);
  }, [id]);

  const handleMessage = () => {
    setLoading(false);
    setMessage(t('tournament.thereAreNoMoreTeams'));
  }

  const getTeams = async () => {
    setLoading(true);
    const status = await getTeam(firebase, dispatch, id, 20);
    status ? setLoading(false) : handleMessage();
  }

  useEffect(() => {
    getTeams();
  }, []);

  const chunkArray = (myArray, size) => {
    let index = 0;
    let arrayLength = myArray.length;
    let arrayTemp = [];
    
    for (index = 0; index < arrayLength; index += size) {
      const arraysUnion = myArray.slice(index, index + size);
      arrayTemp.push(arraysUnion);
    }
    return arrayTemp;
  }

  const saveTeams = (teams) => {
    console.log('teams to save ', ...teams);
    firebase.db
      .collection('rounds')
      .doc(id)
      .collection('round')
      .doc('round1')
      .set({
        idTournament: id,
        round: 'Round1',
        teams
      })
      .then(() => {
      })
  }

  const ejectTeams = () => {
    if (team && detail && detail.tournamentFormat === FORMAT_TOURNAMENT[1].id) {
      const num = 2;
      let teams = [];
      if (num > 1) {
        teams = chunkArray(team, 2);
        const seeds = teams.map((t, i) => {
          let seed = {
            id: i+1,
            date: new Date().toDateString(),
            teams: t.map(g => {
              return {
                id: g.id,
                name: g.teamName,
                participants: g.participants
              }
            })
          };
          return seed;
        })
        const round = [{
          title: 'Round One',
          seeds
        }]
        //setRounds(round);
        saveTeams(round);
      }
    }
  }

  useEffect(() => {
    const rounds = [];
    firebase.db
      .collection('rounds')
      .doc(id)
      .collection('round')
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          rounds.push(...doc.data().teams);
        });
      })
    setRounds(rounds);
  },[])

  console.log('teasms ', rounds);

  const registeredTeam = userData && team && team.filter(c => c.teamCaptain === userData.userId);

  return(
    <>
      {detail && <SubheaderDetail {...detail} setOpen={setOpen} user={userData} team={team} />}
      <div style={{ padding: '20px 0 70px', background: '#F4F4F4 0% 0% no-repeat padding-box' }}>
        
        {detail && detail.sponsors.length > 0 && (
          <Sponsors sponsors={detail.sponsors} />
        )}
        {detail && (
          <Detail
            {...detail}
            setOpen={setOpen}
            team={team}
            user={userData}
            registeredTeam={registeredTeam}
          />
        )}
        {/*rounds && rounds.length > 0 && <Bracket rounds={rounds} />}
        <Button onClick={ejectTeams}>
          Probar
        </Button>*/}
        <div className="text-center">
          <br />
          <Button
            loading={loading && true}
            type="button"
            className="btn-link-blue"
            onClick={getTeams}
            disabled={loading && true}
            style={{ marginRight: 0 }}
          >
            <span>{t('tournament.clickToSeeOtherTeams')}</span>
            <br />
            <Icon name="angle down" size="big" />
          </Button>
        </div>
      </div>
      {userData && open && detail && (
        <AddParticipants
          open={open}
          setOpen={setOpen}
          format={detail && detail.format}
          username={userData.username}
          platform={userData.platform}
          idTournament={id}
          isPrivate={detail.isPrivate}
          userId={userData.userId}
          tournamentFormat={detail.tournamentFormat}
        />
      )}
      {message && (
        <FloatingMessage
          message={message}
          isOpen={!!message}
          close={() => setMessage(null)}
        />
      )}
    </>
  )
}