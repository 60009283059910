import React, { useState, useContext, useCallback } from 'react';
import { Loader, Icon, Button } from 'semantic-ui-react';
import Autosuggest from 'react-autosuggest';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FirebaseContext } from 'server';
import { debounce } from 'lodash';
import FloatingMessage from 'components/FloatingMessage';

const getSuggestions = async (value, firebase) => {
  const query = value.trim();
  if (query === "") {
    return [];
  }
  if (value.length > 3) {
    return await firebase.db.collection('users')
      .where('username', '>=', query).where('username', '<=', query+ '\uf8ff')
      .get()
      .then((({ docs }) => {
        const users = docs.map(doc => doc.data())
        return users;
      }))
  }
  return []
}

function renderSuggestion(suggestion) {
  if (suggestion.text) {
    return <span>{`${suggestion.text} - ${suggestion.val}`}</span>
  }
  return <span>{`${suggestion.name} - ${suggestion.username}`}</span>;
}
export default function SearchUsersPlatform({
  name, control, defaultValue, placeholder, clearErrors, required, getPlayers, index
}) {
  const [valueInput, setValueInput] = useState(defaultValue ? defaultValue : '');
  const [suggestion, setSuggestion] = useState([]);
  const [noUser, setNoUser] = useState(null);
  const firebase = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('app');

  const loadSuggestions = (value) => {
    setLoading(true);
    setTimeout(async () => {
      const sugg = await getSuggestions(value, firebase);
      setSuggestion([]);
      if (value.length > 2) {
        setSuggestion(sugg);
        setLoading(false);
        if (sugg.length <= 0 ) {
          setNoUser(t('commons.messageNoUsernameFound'));
          setSuggestion([{ text: t('commons.noResultsFoundFor'), val: value }]);
        } else {
          setNoUser(null);
        }
      } else {
        setLoading(false);
      }
    }, 100);
  }

  const debounceLoadData = useCallback(debounce(loadSuggestions, 1000), []);
  
  const onChange = (event, { newValue }) => {
    setValueInput(newValue);
    newValue !== control.getValues(name) && control.setValue(name, "");
    newValue.length > 0 && setSuggestion([{ text: t('commons.searching'), val: newValue }]);
    return newValue;
  };

  const onSuggestionsFetchRequested = ({ value }) => debounceLoadData(value);

  const onSuggestionsClearRequested = () => setSuggestion([]);

  const getSuggestionValue = suggestion => {
    if (suggestion.username) {
      control.setValue(name, suggestion.username);
      getPlayers(index, {
        userId: suggestion.userId,
        username: suggestion.username,
        platform: suggestion.platform
      })
      clearErrors([name])
      return suggestion.username;
    }
    if (suggestion.text) {
      return ""
    }
  };

  const inputProps = {
    name,
    placeholder,
    required,
    value: valueInput,
    autoComplete: "off",
    onChange
  };

  const classes = {
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 3,
      left: 0,
      right: 0,
      maxHeight: 250,
      overflow: 'auto',
      backgroundColor: 'white',
      borderRadius: '4px',
      border: '1px solid #dededf'
    },
    suggestionsList: {
      paddingLeft: 0,
    },
    suggestion: {
      display: 'block',
      padding: 5,
      color: '#464242',
      fontSize: '0.97rem'
    },
    suggestionHighlighted: {
      color: '#2EB4CC',
      cursor: 'pointer'
    }
  };

  return (
    <div className="relative">
      {loading && <Loader inline size="tiny" className="loading-username"/>}
      {valueInput && (
        <Button
          type="button"
          size="tiny"
          onClick={() => {
            setValueInput("")
            setSuggestion([])
            control.setValue(name, "")
          }}
          className="close-username"
        >
          <Icon name="close" />
        </Button>
      )}
      <Controller
        as={
          <Autosuggest
            suggestions={suggestion}
            theme={classes}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
          />
        }
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required: true }}
      />
      {noUser && (
        <FloatingMessage
          message={noUser}
          isOpen={!!noUser}
          close={() => setNoUser(null)}
        />
      )}
    </div>
  );
}
