import { useEffect } from 'react';
import { Message } from 'semantic-ui-react';

const FloatingMessage = ({ message, isOpen, close }) => {
	useEffect(() => {
		setTimeout(() => {
			close(false);
		}, 2000)
	}, [isOpen, close]);

	if (isOpen) {
		return (
			<div className="floating-message-bottom">
				<Message
					floating
					color="black"
					onDismiss={close}
				>
					{message}
				</Message>
			</div>
		)
	}
	return null;
}

export default FloatingMessage;