import { MESSAGE, GET_DATA_USER, LOADING_GET_DATA_USER } from './constants';

const commonReducer = (state, action) => {
  switch (action.type) {
    case MESSAGE:
      return {
        ...state,
        message: action.message
      }
    case GET_DATA_USER: {
      return {
        ...state,
        userData: action.userData
      }
    }
    case LOADING_GET_DATA_USER: {
      return {
        ...state,
        loadingUser: action.loadingUser
      }
    }
    default:
      return state
  }
}

export default commonReducer;