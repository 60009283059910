import { Container } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import style from './Prefooter.module.css';

export default function Testimony({
  name,
  description,
  socialLink,
  position,
  image
}) {
  const { t } = useTranslation('app');

  return (
    <section
      className={style.Prefooter}
      style={{ backgroundImage: `url(${image})` }}
    >
      <Container>
        <img className={style.iconquote} src="/images/icons/icon-qleft.png" />
        <p className={style.text}>
          {t(description)}
        </p>
        <br />
        <a
          className={style.email}
          href={socialLink}
          target="_blank"
        >
          {name}
        </a>
        <p className={style.founder}>
          {t(position)}
        </p>
      </Container>
    </section>
  )
}