import { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Language = ({ textColor }) => {
  const { t, i18n } = useTranslation('app');
  const [language, setLanguage] = useState({ value: i18n.language });

  const handleChange = (e, { value }) => {
    setLanguage({ value })
    i18n.changeLanguage(value);
  }

  const LANGUAGES = [
    { key: 'en', text: t('lang.english'), value: 'en' },
    { key: 'es', text: t('lang.spanish'), value: 'es' }
  ]

  return (
    <Dropdown
      button
      labeled
      value={language.value}
      text={language.value}
      fluid
      style={{ color: textColor }}
    >
      <Dropdown.Menu>
        {LANGUAGES.map((lang, i) => (
          <Dropdown.Item
            key={lang.value}
            value={lang.value}
            text={t(lang.text)}
            onClick={handleChange}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown> 
  )
}

export default Language;
