import style from '../Privacy.module.css';

export default function PrivacyEs() {
  return (
    <>
      <p className={style.subtitle}>
        PROTECCIÓN DE LA INFORMACIÓN PERSONAL, PRIVACIDAD Y POLÍTICA DE ORGANIZACIÓN DE TORNEOS
      </p>

      <div>
        <h2 className={style.titleItems}>
          Introducción
        </h2>
        <p className={style.text}>
          92 Tournaments, una plataforma creada por GameFather92 se compromete a respetar su privacidad y
          reconoce su necesidad de protección y gestión adecuadas de cualquier información de identificación
          personal ("Información personal") que comparte con nosotros. 92 Tournaments ha establecido esta Política
          de Privacidad y Organización de Torneos para permitirle saber qué información podemos recopilar de usted
          en la parte disponible públicamente de nuestro sitio web https://www.92tournaments.com/ y a través de
          varias otras interacciones con usted, y cómo podemos usar y compartir esa información. información.
          Tómese un momento para revisar los términos de nuestra Política de privacidad y organización de torneos.
          Al utilizar el Sitio y / o proporcionarnos información personal, acepta los términos de nuestra Política de
          privacidad y organización de torneos. Si no está de acuerdo con los términos de esta Política de privacidad
          y organización de torneos, no utilice el Sitio ni nos proporcione información personal. Esta Política de
          Privacidad y Organización de Torneos se aplica a la información que recopilamos en el Sitio y a la
          información que podemos recopilar por otros medios "fuera de línea".
        </p>
      </div><br />

      <div>
        <h2 className={style.titleItems}>
          Política de privacidad
        </h2>
        <p className={style.text}>
          Proteger su información privada es nuestra prioridad. Esta declaración de privacidad se aplica a
          https://www.92tournaments.com/ y GameFather92 y rige la recopilación y el uso de datos. A los efectos de
          esta Política de privacidad y organización de torneos, a menos que se indique lo contrario, todas las
          referencias a GameFather92 incluyen https://www.92tournaments.com/ y 92 Tournaments. El sitio web de
          92 Tournaments es una plataforma de servicio completo que ofrece la oportunidad de crear y organizar
          torneos de juegos a partir de una amplia variedad de juegos y escenarios por parte de terceros u
          organizaciones que deseen desarrollar sus torneos personales no profesionales o profesionales. Al utilizar
          el sitio web 92 Tournaments, usted acepta las prácticas relacionadas con los datos que se describen en
          esta declaración.
        </p>

        <h2 className={style.subtitleItems}>
          Recopilación de su información personal
        </h2>
        <p className={style.text}>
          Con el fin de brindarle mejor los productos y servicios ofrecidos en nuestro Sitio, 92 Tournaments
          pueden recopilar información de identificación personal, como su:
        </p>
        <ul className={style.list}>
          <li>Nombre y apellido</li>
          <li>Dirección postal</li>
          <li>Dirección de correo electrónico</li>
          <li>Número de teléfono</li>
          <li>Nombres de usuario de juegos de diferentes plataformas o juegos</li>
          <li>Fecha de nacimiento</li>
        </ul>
        <p className={style.text}>
          92 Tournaments también pueden recopilar información demográfica anónima, que no es exclusiva de
          usted, como su:
        </p>
        <ul className={style.list}>
          <li>Edad</li>
          <li>Género</li>
          <li>Raza</li>
        </ul>

        <p className={style.text}>
          No recopilamos ninguna información personal sobre usted a menos que nos la proporcione
          voluntariamente. Sin embargo, es posible que se le solicite que nos proporcione cierta información
          personal cuando elija utilizar determinados productos o servicios disponibles en el Sitio. Estos pueden
          incluir: (a) registrarse para obtener una cuenta en nuestro Sitio; (b) participar en un sorteo o concurso
          patrocinado por nosotros o uno de nuestros socios; (c) suscribirse a ofertas especiales de terceros
          seleccionados; (d) enviándonos un mensaje de correo electrónico. Es decir, utilizaremos su información
          para, entre otros, comunicarnos con usted en relación con los servicios y / o productos que nos haya
          solicitado. También podemos recopilar información adicional personal o no personal en el futuro.
        </p>
      </div><br /><br />

      <div>
        <h2 className={style.titleItems}>
          Uso de su información personal
        </h2>
        <p className={style.text}>
          92 Tournaments recopila y utiliza su información personal para operar sus sitios web y brindar los
          servicios que ha solicitado.
          <br /><br />
          92 Tournaments también puede usar su información que puede ser identificada personalmente para
          informarle de otros productos o servicios disponibles en 92 Tournaments y sus afiliados o patrocinadores.
        </p>

        <h2 className={style.subtitleItems}>
          Compartir información con terceros
        </h2>
        <p className={style.text}>
          92 Tournaments no realiza por ningún motivo la venta, alquiler o cesión sus listas de clientes a terceros.
          <br /><br />
          92 Tournaments puede compartir datos con socios confiables para ayudar a realizar análisis estadísticos,
          enviarle correo electrónico o correo postal, brindar atención al cliente u organizar las entregas. Todos
          estos terceros tienen prohibido usar su información personal, excepto para proporcionar estos servicios a
          92 Tournaments, y están obligados a mantener la confidencialidad de su información.
          <br /><br />
          92 Tournaments puede divulgar su información personal, sin previo aviso, si así lo requiere la ley o si se
          cree de buena fe que dicha acción es necesaria para: (a) cumplir con los edictos de la ley o cumplir con
          el proceso legal servido en 92 Tournaments o el sitio; (b) proteger y defender los derechos o la propiedad
          del 92 Tournaments; y / o (c) actuar bajo circunstancias exigentes para proteger la seguridad personal de
          los usuarios de 92 Tournaments, o del público.
        </p>

        <h2 className={style.subtitleItems}>
          Seguimiento del comportamiento del usuario
        </h2>
        <p className={style.text}>
          92 Tournaments puede realizar un seguimiento de los sitios web y las páginas que visitan nuestros
          usuarios dentro de 92 Tournaments, con el fin de determinar qué servicios de 92 Tournaments son los
          más populares. Estos datos se utilizan para entregar contenido personalizado y publicidad dentro de 92
          Tournaments a los clientes cuyo comportamiento indica que están interesados en un área temática en
          particular
        </p>

        <h2 className={style.subtitleItems}>
          Información recopilada automáticamente
        </h2>
        <p className={style.text}>
          La información sobre el hardware y el software de su computadora puede ser recopilada automáticamente
          por 92 Tournaments. Esta información puede incluir: su dirección IP, tipo de navegador, nombres de
          dominio, tiempos de acceso y direcciones de sitios web de referencia. Esta información se utiliza para el
          funcionamiento del servicio, para mantener la calidad del servicio y para proporcionar estadísticas
          generales sobre el uso del sitio web de 92 Tournaments.
        </p>

        <h2 className={style.subtitleItems}>
          Uso de cookies
        </h2>
        <p className={style.text}>
          El sitio web de 92 Tournaments puede utilizar "cookies" para ayudarlo a personalizar su experiencia en
          línea. Una cookie es un archivo de texto que un servidor de páginas web coloca en su disco duro. Las
          cookies no se pueden utilizar para ejecutar programas o enviar virus a su computadora. Las cookies se le
          asignan de forma exclusiva a usted y solo puede leerlas un servidor web en el dominio que le emitió la
          cookie.
          <br /><br />
          Uno de los propósitos principales de las cookies es proporcionar una función de conveniencia para
          ahorrarle tiempo. El propósito de una cookie es decirle al servidor web que ha regresado a una página
          específica. Por ejemplo, si personaliza las páginas de 92 Tournaments, o se registra en el sitio o en un
          servicio de 92 Tournaments, una cookie ayuda a 92 Tournaments a recordar su información específica
          para las visitas siguientes. Esto simplifica el proceso de registrar su información personal, como el
          nombre de usuario, el usuario del juego en cada registro de la plataforma, etc. Cuando regrese al mismo
          sitio web de 92 Tournaments, la información que proporcionó anteriormente se puede recuperar, por lo
          que puede usar fácilmente las funciones de 92 Tournaments que personalizó.
          <br /><br />
          Tiene la capacidad de aceptar o rechazar cookies. La mayoría de los navegadores web aceptan
          automáticamente las cookies, pero normalmente puede modificar la configuración de su navegador para
          rechazar las cookies si lo prefiere. Si opta por rechazar las cookies, es posible que no pueda experimentar
          por completo las funciones interactivas de los servicios o sitios web de 92 Tournaments que visita
        </p>

        <h2 className={style.subtitleItems}>
          Seguridad de su información personal
        </h2>
        <p className={style.text}>
          92 Tournaments protege su información personal frente al acceso, uso o divulgación no autorizados. 92
          Tournaments utiliza los siguientes métodos para este propósito:
        </p>
        <ul className={style.list}>
          <li>Protocolo SSL</li>
        </ul>
        <p className={style.text}>
          Cuando se transmite información personal (como un número de tarjeta de crédito) a otros sitios web, se
          protege mediante el uso de cifrado, como el protocolo Secure Sockets Layer (SSL).
          <br /><br />
          Nos esforzamos por tomar las medidas de seguridad adecuadas para proteger contra el acceso no
          autorizado o la alteración de su información personal. Desafortunadamente, no se puede garantizar que la
          transmisión de datos a través de Internet o cualquier red inalámbrica sea 100% segura. Como resultado,
          aunque nos esforzamos por proteger su información personal, usted reconoce que: (a) existen limitaciones
          de seguridad y privacidad inherentes a Internet que están fuera de nuestro control; y (b) no se puede
          garantizar la seguridad, integridad y privacidad de toda la información y los datos intercambiados entre
          usted y nosotros a través de este Sitio.
        </p>

        <h2 className={style.subtitleItems}>
          Derecho a supresión
        </h2>
        <p className={style.text}>
          Sujeto a ciertas excepciones que se establecen a continuación, al recibir una solicitud verificable de usted, nosotros:
        </p>
        <ul className={style.list}>
          <li>Eliminar su información personal de nuestros registros</li>
          <li>Indique a los proveedores de servicios que eliminen su información personal de sus registros.</li>
        </ul>

        <p className={style.text}>
          Tenga en cuenta que es posible que no podamos cumplir con las solicitudes para eliminar su información
          personal si es necesario para:
        </p>
        <ul className={style.list}>
          <li>
            Completar la transacción para la cual se recopiló la información personal, cumplir con los términos
            de una garantía escrita o retiro de producto realizado de acuerdo con la ley federal, proporcionar un bien o
            servicio solicitado por usted o razonablemente anticipado dentro del contexto de nuestra relación comercial
            en curso con usted, o ejecute un contrato entre usted y nosotros.
          </li>
          <li>
            Detectar incidentes de seguridad, protegerse contra actividades maliciosas, engañosas,
            fraudulentas o ilegales; o enjuiciar a los responsables de esa actividad.
          </li>
          <li>
            Depurar para identificar y reparar errores que perjudiquen la funcionalidad prevista existente.
          </li>
          <li>
            Ejercer la libertad de expresión, garantizar el derecho de otro consumidor a ejercer su derecho a
            la libertad de expresión o ejercer otro derecho previsto por la ley.
          </li>
          <li>
            Cumplir con la Ley de privacidad de comunicaciones electrónicas de California.
          </li>
          <li>
            Permitir únicamente usos internos que estén razonablemente alineados con sus expectativas en
            función de su relación con nosotros.
          </li>
          <li>
            Cumplir con una obligación legal existente; o de lo contrario, use su información personal, internamente, de una manera legal que sea
            compatible con el contexto en el que proporcionó la información.
          </li>
        </ul>

        <h2 className={style.subtitleItems}>
          Niños menores de trece
        </h2>
        <p className={style.text}>
          92 Tournaments no recopila a sabiendas información de identificación personal de niños menores de trece
          años. Si es menor de trece años, debe pedir permiso a sus padres o tutores para utilizar este sitio web.
        </p>

        <h2 className={style.subtitleItems}>
          Comunicaciones por correo electrónico
        </h2>
        <p className={style.text}>
          De vez en cuando, 92 Tournaments puede comunicarse con usted por correo electrónico con el fin de
          proporcionar anuncios, ofertas promocionales, alertas, confirmaciones, encuestas y / u otra comunicación
          general. Para mejorar nuestros Servicios, es posible que recibamos una notificación cuando abra un correo
          electrónico de 92 Tournaments o haga clic en un enlace en el mismo.
        </p>

        <h2 className={style.subtitleItems}>
          Ley que rige
        </h2>
        <p className={style.text}>
          92 Tournaments no garantiza que esta Política de privacidad y dichas prácticas cumplan con las leyes de
          cualquier otro país. Los visitantes que usan el Sitio y residen fuera de los Estados Unidos lo hacen por su
          propia iniciativa y son responsables del cumplimiento de las leyes locales, si y en la medida en que las
          leyes locales sean aplicables. Si reside fuera de los Estados Unidos, al utilizar nuestro Sitio, acepta la
          transferencia y el uso de su información fuera de su país
        </p>

        <h2 className={style.subtitleItems}>
          Cambios a esta declaración
        </h2>
        <p className={style.text}>
          92 Tournaments se reserva el derecho de cambiar esta Política de privacidad de vez en cuando. Le
          notificaremos sobre cambios significativos en la forma en que tratamos la información personal enviando
          un aviso a la dirección de correo electrónico principal especificada en su cuenta, colocando un aviso
          destacado en nuestro sitio y / o actualizando cualquier información de privacidad en esta página. Su uso
          continuado del Sitio y / o los Servicios disponibles a través de este Sitio después de tales modificaciones
          constituirá su: (a) reconocimiento de la Política de Privacidad modificada; y (b) el acuerdo de cumplir y estar
          sujeto a esa Política
        </p>

        <h2 className={style.subtitleItems}>
          Política de organización del torneo:
        </h2>
        <p className={style.text}>
          Como miembro de la plataforma 92 Tournaments, puede organizar y establecer libremente todo tipo de
          torneos de una amplia variedad de juegos como Call Of Duty: Warzone, Valorant o Fortnite, pero de ninguna
          manera esto significa que 92 Tournaments o GameFather92 asume ninguna responsabilidad por el
          cumplimiento del compromiso que usted como organizador asume al organizar un torneo en la plataforma,
          usted como organizador de un torneo acepta sin errores que es su única obligación como organizador
          asumir plena y total responsabilidad por los detalles y establecimientos con los que decida comprometerse
          creando un torneo en la plataforma.
          <br /><br />
          Además, si no está organizando un torneo, pero está actuando como miembro en uno de ellos, declara al
          aceptar esta política que dejará GameFather92 o 92 Tournaments inofensivo de cualquier acción o reclamo
          contra un torneo y usted dirigirá o sus quejas al organizador o al torneo en concreto, siendo él el único
          responsable del buen desempeño y cumplimiento de los requisitos y premios establecidos.
        </p>

        <h2 className={style.subtitleItems}>
          Política de premios o premios:
        </h2>
        <p className={style.text}>
          GameFather92 o 92 Tournaments no se hace responsable en modo alguno de los premios o galardones
          que se establezcan en cualquiera de los torneos creados en la plataforma del sitio web, 92 Tournaments y
          GameFather92 solo sirven como creador de una plataforma web para que los gamers y organización
          puedan desarrollar sus torneos con los premios, se comprometen exclusivamente a cumplir. De ninguna
          manera la plataforma o GameFather92 se hace responsable del incumplimiento de estos premios o del
          requisito establecido de cada torneo por parte de cada organizador.
          <br /><br />
          Como se indicó, 92 Tournaments y GameFather92 solo sirven como proveedores de una plataforma para
          facilitar la creación de torneos de juegos, pero no son responsables de ningún premio o reclamo de premio
          o problema que pueda ocurrir, esto es responsabilidad exclusiva del organizador y patrocinador de cada
          torneo si entonces.
        </p>

        <h2 className={style.subtitleItems}>
          Información del contacto
        </h2>
        <p className={style.text}>
          92 Tournaments agradece sus preguntas o comentarios con respecto a esta Declaración de Privacidad y
          Política de Realización de Torneos. Si cree que 92 Tournaments no se ha adherido a esta Declaración,
          comuníquese con 92 Tournaments en:
          <br />
          Dirección de correo electrónico:
          <br />
          info@gamefather92.com
          <br /> <br /><br />
          Efectivo a partir del 10 de abril de 2021.
        </p>
      </div>

    </>
  )
}