import React, { useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import style from './Editor.module.css';

const WYSIWYGEditor = props => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = editorState => {
    setEditorState(editorState);
    return props.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  return (
    <>
      <div className={style.editor}>
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName={`editor-class ${style.wrapper}`}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'emoji']
          }}
        />
      </div>
    </>
  );
};

export default WYSIWYGEditor;
