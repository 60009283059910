import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const withHelmet = ({
  title,
  description,
  keywords
}) => WrappedComponent => props => {
  const { t } = useTranslation('app');
  return (
    <>
      <Helmet>
        <title>{t(title)}</title>
        <meta name="description" content={t(description)} />
        <meta property="og:title" content={t(title)} />
        <meta property="og:description" content={t(description)} />
        <meta property="twitter:title" content={t(title)} />
        <meta property="twitter:description" content={t(description)} />
        <meta name="keywords" content={t(keywords)} />
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );
};

export default withHelmet;
