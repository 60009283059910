import { PLAYER_DATA, LOADING_PLAYER_DATA, MESSAGE_PLAYER_DATA } from './constants';

export const getPlayerInformation = async (dispatch, firebase, values) => {
  dispatch({
    type: LOADING_PLAYER_DATA,
    loadingPlayer: true
  })
  const { username, platform } = values;
  let playerInfo = firebase.functions.httpsCallable('getUserInfo');
  const req = playerInfo({
    username,
    platform
  })
  .then((result) => {
    if (typeof result.data === 'string'){
      dispatch({
        type: MESSAGE_PLAYER_DATA,
        messagePlayer: result.data
      })
    } else {
      dispatch({
        type: PLAYER_DATA,
        data: result.data
      })
    }
    dispatch({
      type: LOADING_PLAYER_DATA,
      loadingPlayer: false
    })
  })
  .catch(() => {
    dispatch({
      type: LOADING_PLAYER_DATA,
      loadingPlayer: false
    })
  })
}

export const closeMessage = (dispatch) => {
  dispatch({ type: MESSAGE_PLAYER_DATA, messagePlayer: null });
}

export const clearPlayer = (dispatch) => {
  dispatch({ type: PLAYER_DATA, data: null });
  closeMessage(dispatch);
}