import { Segment, Form, Button } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import timezones from 'timezones-list';
import { GAMES, FORMAT_TOURNAMENT } from 'utils/constants';
import DropzoneComponent from 'components/DropzoneComponent';
import schema from './schemaInformation';

export default function TabInformation({ onSubmit }) {
  const { t } = useTranslation('app');

  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const listTimezones = timezones.filter(item => (item.tzCode.includes('America') || item.tzCode.includes('Europe')));

  const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  }

  return (
    <Form size="large" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
      <Segment textAlign="left" color="teal" stacked padded="very">

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t('tournaments.form.name')}</label>
            <input
              type="text"
              name="name"
              placeholder={t('tournaments.form.name')}
              ref={register} 
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
          <Form.Field required>
            <label>{t('tournaments.form.startDate')}</label>
            <Controller
              name="startDate"
              control={control}
              defaultValue=""
              placeholderText={t('tournaments.form.selectDate')}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  dateFormat="dd/MM/yyyy, h:mm aa"
                  startDate={new Date()}
                  minDate={new Date()}
                  filterTime={filterPassedTime}
                  showTimeSelect
                  wrapperClassName="full-width"
                  placeholderText={t('tournaments.form.selectDate')}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="startDate"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('tournaments.form.zone')}</label>
            <Controller
              name="zone"
              control={control}
              defaultValue={listTimezones[22].tzCode}
              as={
                <select>
                  {listTimezones.map(zone => (
                    <option key={zone.tzCode} value={zone.tzCode}>{zone.tzCode}</option>
                  ))}
                </select>
              }
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t('tournaments.form.gameDuration')}</label>
            <input
              type="text"
              name="gameDuration"
              placeholder={t('tournaments.form.inHours')}
              maxLength="2"
              ref={register}
            />
            <ErrorMessage
              errors={errors}
              name="gameDuration"
              render={({ message }) => <small className="red">{t(message, { minHour: '1', maxHour: '10'})}</small>}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('tournaments.form.videoUrl')}</label>
            <input
              type="url"
              placeholder="http://video.com"
              ref={register}
              name="videoUrl"
            />
            <ErrorMessage
              errors={errors}
              name="videoUrl"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('tournaments.form.game')}</label>
            <Controller
              name="game"
              defaultValue={GAMES[0].id}
              control={control}
              as={
                <select>
                  {GAMES.map(game => (
                    <option key={game.id} value={game.id} disabled={game.id === 'warzone' ? false : true}> {game.name} </option>
                  ))}
                </select>
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="3">
          <Form.Field>
            <label>Formato del torneo</label>
            <Controller
              name="tournamentFormat"
              defaultValue={FORMAT_TOURNAMENT[0].id}
              control={control}
              as={
                <select>
                  {FORMAT_TOURNAMENT.map(format => (
                    <option key={format.id} value={format.id}> {format.name} </option>
                  ))}
                </select>
              }
            />
            </Form.Field>
          <Form.Field>
            <label>{t('tournaments.form.image')}</label>
            <Controller
              name="image"
              control={control}
              defaultValue=""
              render={() => (
                <DropzoneComponent
                  multiple={false}
                  maxSize={20971520}
                  acceptFiles="image/*"
                  setValue={setValue}
                  name="image"
                />
              )}
            />
          </Form.Field>
        </Form.Group>

        <br />
        <div className="text-center">
          <Button
            color="teal"
            content={t('tournaments.form.next')}
            icon="arrow right"
            size="large"
            labelPosition="right"
          />
        </div>      
      </Segment>
    </Form>
  )
}