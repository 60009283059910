import { Header, Segment } from 'semantic-ui-react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import CardTournaments from 'components/Cards/CardTournaments';

export default function ListTournaments({ list }) {
  const { t } = useTranslation('app');

  const settings = {
    dots: false,
    infinite: list.length > 3 ? true : false,
    speed: 2500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Segment raised style={{ border: 0, padding: '20px 20px 30px' }}>
      <Header as="h3" style={{ padding: '5px 0 15px 15px' }}>
        <Header.Subheader className="subtitle-color">
          {t('home.morePopular')}
        </Header.Subheader>
        <span className="title-color">
          {t('home.topTournaments')}
        </span>
      </Header>
      <Slider {...settings}>
        {list.map(tournament => (
          <CardTournaments key={tournament.id} {...tournament} />
        ))}
      </Slider>
    </Segment>
  )
}