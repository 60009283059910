import { useEffect, useContext, useState } from 'react';
import { Container, Segment, Header, Grid, Button } from 'semantic-ui-react';
import { FirebaseContext } from 'server';
import { useTranslation } from 'react-i18next';
import { useStateValue } from 'context/store';
import { listAllTournaments } from 'context/tournaments/actions';
import HeaderInternals from 'components/Layouts/Layout/Header';
import Subheader from './components/Subheaders/Subheader';
import CardTournamentsUpcoming from 'components/Cards/CardTournaments/CardTournamentsUpcoming';
import FloatingMessage from 'components/FloatingMessage';

const LIMIT = 4;

export default function Tournaments() {
  const { t } = useTranslation('app');
  const firebase = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [{tournaments: { listAll }, commons: { userData }}, dispatch] = useStateValue();

  const handleMessage = () => {
    setLoading(false);
    setMessage(t('tournament.thereAreNoMoreTournaments'));
  }

  const getTournaments = async () => {
    setLoading(true);
    const status = await listAllTournaments(firebase, dispatch, LIMIT);
    status ? setLoading(false) : handleMessage();
  }

  useEffect(() => {
    getTournaments();
  }, []);

  return (
    <div style={{ backgroundColor: '#F4F4F4', paddingBottom: 10 }}>
      <HeaderInternals height="300px">
        <Subheader
          link="/app/tournaments/new"
          nameLink={t('tournament.hostATournament')}
          user={userData ? userData : null}
          id={listAll && listAll.length > 0 && listAll[Math.floor(Math.random() * listAll.length)].id}
        />
      </HeaderInternals>
      <Container>
        <Segment basic style={{ border: 0, padding: '30px 20px 30px' }} secondary>
          <Header as="h3" style={{ padding: '5px 0 15px 15px' }}>
            <span className="title-color">
              {t('tournament.tournaments')}
            </span>
          </Header>
          <Grid columns={3} stackable>
            <Grid.Row>
              {listAll && listAll.map(tournament => (
                <Grid.Column key={tournament.id} className="container-card">
                  <CardTournamentsUpcoming key={tournament.id} {...tournament} />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
          <div className="text-center">
            <Button
              loading={loading && true}
              content={t('commons.seeMore')}
              type="button"
              className="btn-primary btn-red"
              onClick={getTournaments}
              disabled={loading && true}
            />
          </div>
        </Segment>      
      </Container>
      {message && (
        <FloatingMessage
          message={message}
          isOpen={!!message}
          close={() => setMessage(null)}
        />
      )}
    </div>
  )
}