import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { PLATFORMS } from 'utils/constants';
import { schema, schemaEdit } from './schema';

const SignupForm = ({ submit,  user }) => {
  const { t } = useTranslation('app');

  const { register, handleSubmit, errors, control, watch, clearErrors } = useForm({
    mode: 'onChange',
    resolver: user ? yupResolver(schemaEdit) : yupResolver(schema)
  });
  
  const havePlatform = () => {
    const index = PLATFORMS.findIndex(x => x.id === user.platform);
    return PLATFORMS[index].id
  }

  return (
    <>
      <Form size="large" onSubmit={handleSubmit(submit)} noValidate>
        <Segment padded textAlign="left">
          <Header as="h3" color="grey" textAlign="left">
            {t('session.personalInformation')}
            <Header.Subheader>
              {t('commons.fieldsAreRequired')}
            </Header.Subheader>
          </Header>

          <Form.Field required error={!!errors.name}>
            <input
              type="text"
              name="name"
              placeholder={`${t('session.fullName')} *`}
              ref={register}
              defaultValue={user ? user.name : ''}
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>

          <Form.Field required error={!!errors.email} disabled={user && true}>
            <input
              type="email"
              name="email"
              placeholder={`${t('session.email')} *`}
              ref={register}
              defaultValue={user ? user.email : ''}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>

          <Form.Group widths='equal'>
            <Form.Field required error={!!errors.platform}>
              <Controller
                as={
                  <select>
                    {PLATFORMS.map(platform => (
                      <option value={platform.id} key={platform.id}>
                        {platform.name}
                      </option>
                    ))}
                  </select>
                }
                control={control}
                name="platform"
                defaultValue={user ? havePlatform() : PLATFORMS[0].id}
              />
              <ErrorMessage
                errors={errors}
                name="platform"
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>

            <Form.Field required error={!!errors.username}>
              {user && watch('platform') ? (
                <SearchAutocomplete
                  control={control}
                  name="username"
                  defaultValue={user.username ? user.username : ''}
                  placeholder={`${t('session.searchUser')} *`}
                  platform={watch('platform') ? watch('platform') : PLATFORMS[0].id}
                  clearErrors={clearErrors}
                />
              ) : (
                <SearchAutocomplete
                  control={control}
                  name="username"
                  defaultValue=""
                  placeholder={`${t('session.searchUser')} *`}
                  platform={watch('platform') ? watch('platform') : PLATFORMS[0].id}
                  clearErrors={clearErrors}
                />
              )}
              <p style={{ color: '#767676', margin: '5px 0 3px', fontSize: '12px' }}>
                {t('commons.writeAndChooseAUserFromTheList')}
              </p>
              <ErrorMessage
                errors={errors}
                name="username"
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>
          </Form.Group>
          
          {!user && (
            <Form.Field required error={!!errors.password}>
              <input
                type="password"
                name="password"
                ref={register}
                autoComplete="current-password"
                placeholder={`${t('session.password')} *`}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>
          )}
        </Segment>

        <Segment padded textAlign="left">
          <Header as="h3" color="grey" textAlign="left">
            {t('session.socialMedia')}
            <Header.Subheader>
              {t('commons.theFollowingFieldsAreOptional')}
            </Header.Subheader>
          </Header>

          <Form.Group widths="equal">
            <Form.Field>
              <input
                type="text"
                name="socialMedia.fbSocial"
                ref={register}
                placeholder={`${t('commons.facebook')} URL`}
                defaultValue={user && user.socialMedia.fbSocial ? user.socialMedia.fbSocial : ''}
              />
              <ErrorMessage
                errors={errors}
                name="socialMedia.fbSocial"
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>

            <Form.Field>
              <input
                type="text"
                name="socialMedia.twitchSocial"
                ref={register}
                placeholder={`${t('commons.twitch')} URL`}
                defaultValue={user && user.socialMedia.twitchSocial ? user.socialMedia.twitchSocial : ''}
              />
              <ErrorMessage
                errors={errors}
                name="socialMedia.twitchSocial"
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal" className="text-left">
            <Form.Field>
              <input
                type="text"
                name="socialMedia.ytSocial"
                ref={register}
                placeholder={`${t('commons.youtube')} URL`}
                defaultValue={user && user.socialMedia.ytSocial ? user.socialMedia.ytSocial : ''}
              />
              <ErrorMessage
                errors={errors}
                name="socialMedia.ytSocial"
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>

            <Form.Field>
              <input
                type="text"
                name="socialMedia.otherSocial"
                ref={register}
                placeholder={`${t('commons.other')} URL`}
                defaultValue={user && user.socialMedia.otherSocial ? user.socialMedia.otherSocial : ''}
              />
              <ErrorMessage
                errors={errors}
                name="socialMedia.otherSocial"
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
            </Form.Field>
          </Form.Group>

          {!user && (
            <Form.Field inline>
              <Controller
                control={control}
                name="terms"
                defaultValue={false}
                render={(
                  { onChange, onBlur, value },
                ) => (
                  <input
                    type="checkbox"
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
              <a href="/privacy" target="_blank" style={{ verticalAlign: 'middle' }}>
                {t('session.acceptTheTermsAndConditions')}
              </a>
              <br />
              <ErrorMessage
                errors={errors}
                name="terms"
                render={({ message }) => <small className="red">{t(message)}</small>}
              />
              <br /><br />
            </Form.Field>
          )}

          <Button color="teal" fluid size="large">
            {user ? t('commons.edit') : t('session.signup')}
          </Button>
        </Segment>
      </Form>

      {!user && (
        <Message>
          {t('session.doYouAlreadyHaveAUser')} <Link to="/login">{t('session.login')}</Link>
        </Message>
      )}
    </>
  )
}

export default SignupForm;