import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getImageDefault } from 'utils';
import { GAMES } from 'utils/constants';
import style from './CardTournaments.module.css';

export default function CardTournaments(tournament) {
  const { t } = useTranslation('app');
  const { id, image, name, game } = tournament;
  const urlName = name.replace(/[^A-Z0-9]/ig, "-");

  return (
    <Link to={`/tournaments/${id}/${urlName}`} className={style.cardLink}>
      <div
        className={style.card}
        style={{ background: `#00b5ad url(${image ? image : getImageDefault(GAMES, game) })`}}
      >
        <div className={style.cardText}>
          <h3 className={style.name}>
            {name}
          </h3>
          <p className={style.game}>
            {GAMES.filter(gm => gm.id === game)[0].name}
          </p>
          <Button className={style.button}>
            {t('commons.seeMore')}
          </Button>
        </div>
      </div>
    </Link>
  )
}