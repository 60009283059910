import { useEffect, useContext } from 'react';
import { Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FirebaseContext } from 'server';
import { useStateValue } from 'context/store';
import { listTournaments, specialListTournaments } from 'context/tournaments/actions';
import HeaderInternals from 'components/Layouts/Layout/Header';
import ContactUs from 'components/Layouts/Layout/ContactUs';
import Subheader from './components/Subheaders/Subheader';
import ListTournaments from './components/Tournaments/ListTournaments';
import SectionBrands from './components/SectionBrands';
import ListUpcomingTournaments from './components/Tournaments/ListUpcomingTournaments';
import Prefooter from '../../components/Layouts/Layout/Prefooter';
import withHelmet from 'hoc/withHelmet';

function Home() {
  const { t } = useTranslation('app');
  const firebase = useContext(FirebaseContext);
  const [{tournaments: { list, specialList }, commons: { userData }}, dispatch] = useStateValue();

  const getTournaments = async () => await listTournaments(firebase, dispatch);
  const getListSpecial = async () => await specialListTournaments(firebase, dispatch);

  useEffect(() => {
    getTournaments();
  }, []);

  useEffect(() => {
    getListSpecial();
  }, []);

  return (
    <div style={{ backgroundColor: '#F4F4F4', paddingBottom: 10 }}>
      <HeaderInternals height="42vh">
        <Subheader
          link="/app/tournaments/new"
          nameLink={t('home.hostATournament')}
          user={userData ? userData : null}
          id={list && list.length > 0 && list[Math.floor(Math.random() * list.length)].id}
        />
      </HeaderInternals>

      <Container className="info-above">
        {specialList && specialList.length > 0 && <ListTournaments list={specialList} />}
      </Container>

      <Container style={{ margin: '40px 0' }}>
        <SectionBrands />
      </Container>

      {list && <ListUpcomingTournaments list={list} />}

      <Prefooter />

      <ContactUs />
    </div>
  )
}

export default withHelmet({
  title: 'meta.title',
  description: 'meta.description',
  keywords: 'meta.keywords'
})(Home);