import { Container, Segment, Grid, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import ShowMore from 'react-show-more-button';
import CardDuration from 'components/Cards/CardTournaments/CardDuration';
import TeamTable from 'components/Tables/Team/TeamTable';
import Awards from 'components/Cards/Awards';
import ListCardsDetail from './ListCardsDetail';
import { FORMAT, FORMAT_BRACKETS } from 'utils/constants';
import style from './Detail.module.css';

export default function Detail({
  rules, description, format, topKd, maxGroupKd, gameDuration, image, game, tournamentFormat,
  awards, setOpen, team, bestGames, isPrivate, user, zone, startDate
}) {
  const { t } = useTranslation('app');

  const currentTournament = () => {
    let dateTournament = DateTime.fromJSDate(new Date(startDate.toDate()), { zone: zone });
    return (dateTournament.ts > new Date().getTime())
  }

  const formatGame = () => {
    const fg = tournamentFormat === 'brackets' ? FORMAT_BRACKETS.filter(f => f.id === format) : FORMAT.filter(f => f.id === format);
    return fg[0].name
  }

  const createMarkup = (text) => {
    return { __html: text }
  }

  const isRegister = () => {
    if (team) {
      return team.find(t => t.teamCaptain === user.userId);
    }
    return null
  }

  return(
    <Container>
      <Segment padded="very" style={{ marginBottom: 0 }} className={style.containerDetail}>
        <Grid stackable celled='internally'>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={10}>
              <div className={style.containerLeft}>
                {description && (
                  <>
                    <h4 className={style.subtitle}>
                      {t('tournament.description')}
                    </h4>
                    <div className={style.text} dangerouslySetInnerHTML={createMarkup(description)} />
                  </>
                )}
                <br />
                <h4 className={style.subtitle}>
                  {t('tournament.rules')}
                </h4>
                <ShowMore
                  maxHeight={235}
                  button={
                    <button className="btn-link-blue">
                      <span>{t('tournament.clickHereToSeeTheRules')}</span><br />
                      <Icon name="angle down" size="big" />
                    </button>
                  }
                >
                  <div className={style.text} dangerouslySetInnerHTML={createMarkup(rules)} />
                </ShowMore>
                <br /><br />
                <ListCardsDetail
                  firstIcon="icon-clock.svg"
                  firstText={bestGames === 1 ? t('tournament.bestGame') : `${t('tournament.top')} ${bestGames} ${t('tournament.games')}`}
                  secondIcon="icon-users.png"
                  secondText={formatGame()}
                  thirdIcon="icon-key.png"
                  thirdText={isPrivate ? t('tournament.byInvitation') : t('tournament.free')}
                  maxKd={topKd}
                  maxGroupKd={maxGroupKd}
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <CardDuration 
                image={image}
                gameDuration={gameDuration}
                game={game}
              />
              <br /><br />
              <Awards
                awards={awards}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br /><br />
        <div className="text-center">
          {user ? (
            currentTournament() && isRegister() === null && (
              <Button className="btn-primary btn-red" onClick={() => setOpen(true)}>
                {t('tournament.signUpForThisTournament')}
              </Button>
            )
          ) : (
            <Link to="/login" className="ui button btn-primary btn-red">
              {t('tournament.signUpForThisTournament')}
            </Link>
          )}
        </div>
        <br />
      </Segment>
      {team && team.length > 0 && (
        <TeamTable
          data={team}
          bestGames={bestGames}
          format={format}
          user={user}
          tournamentFormat={tournamentFormat}
        />
      )}
    </Container>
  )
}