import Slider from 'react-slick';
import CardDetail from 'components/Cards/CardTournaments/Details/CardDetail';

export default function ListCardsDetail({
  firstIcon, firstText,
  secondIcon, secondText,
  thirdIcon, thirdText,
  maxKd, maxGroupKd
}) {
  
  const settings = {
    dots: false,
    infinite: false,
    speed: 2500,
    slidesToShow: 2,
    rows: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          infinite: true
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      <CardDetail
        icon={firstIcon}
        text={firstText}
      />
      <CardDetail
        icon={secondIcon}
        text={secondText}
      />
      <CardDetail
        icon={thirdIcon}
        text={thirdText}
      />
      <CardDetail
        pointsCard
        maxKd={maxKd}
        maxGroupKd={maxGroupKd}
      />
    </Slider>
  )
}