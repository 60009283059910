import { Message, Icon, Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './Warning.module.css';

export default function Warning() {
  const { t } = useTranslation('app');
  return (
    <div className={style.contain}>
      <Container>
        <Message icon warning className={style.message}>
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header className={style.messageHeader}>
              {t('warningGame.text')}
            </Message.Header>
            <a className={style.buttonLink} href="https://www.youtube.com/watch?v=dqCicTNEmIw" target="_blank">
              {t('warningGame.instructions')}
            </a>
          </Message.Content>
        </Message>
      </Container>
    </div>
  )
}