import { Segment, Container, Grid, Header, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('app');

  return (
    <Segment inverted vertical style={{ padding: "30px" }}>
      <Container>
        <Grid inverted stackable>
          <Grid.Row>

            <Grid.Column width={12}>
              <Header
                inverted
                as="h4"
              >
                {t('footer.allRightsReserved')}
                <Header.Subheader style={{ marginTop: 7 }}>
                  {t('footer.platformIsOwnedBy')}
                </Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column width={4} verticalAlign="middle" textAlign="center">
              <List link inverted horizontal divided>
                <List.Item
                  as="a"
                  target="_blank"
                  href="/privacy"
                >
                  {t('footer.privacyAndTermsOfService')}
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  )
}

export default Footer;