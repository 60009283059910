import {
  FORM_DATA,
  LOADING,
  GET_TEAM,
  GET_MATCHES,
  GET_TOURNAMENT,
  LIST_TOURNAMENTS,
  LIST_ALL_TOURNAMENTS,
  SPECIAL_LIST_TOURNAMENTS
} from './constants';

const tournamentsReducer = (state, action) => {
  switch (action.type) {
    case FORM_DATA:
    	return {
	    	...state,
	    	form: { ...state.form, ...action.form }
      }
    case LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case LIST_TOURNAMENTS:
      return {
        ...state,
        list: action.list
      }
    case SPECIAL_LIST_TOURNAMENTS:
      return {
        ...state,
        specialList: action.specialList
      }
    case GET_TOURNAMENT:
      return {
        ...state,
        detail: action.detail
      }
    case GET_TEAM:
      /*const g = 
        state.team && action.team && action.team.length > 0 && state.team.filter(x => x.id === action.team[0].id).length > 0 ?
          state.team.filter((elem) => {
            return !action.team.find((u) => {
              if(elem.id === u.id){
                return {...u}
              }
            })
          })
        : state.team && state.team.concat(action.team);*/
      /*const result = state.team && state.team.concat(action.team).filter((elem) => {
        return !action.team.find(({ id }) => {
          elem.id === id
        })
      });*/
      return {
        ...state,
        team: state.team ? state.team.concat(action.team) : action.team
      }
    case GET_MATCHES:
      return {
        ...state,
        matches: action.matches
      }
    case LIST_ALL_TOURNAMENTS:
      return {
        ...state,
        listAll: state.listAll ? state.listAll.concat(action.listAll) : action.listAll
      }
    default:
      return state;
  }
}

export default tournamentsReducer;
