import { string, object, array } from 'yup';

const schema = object().shape({
  teamName: string().required('commons.requiredField'),
  participants: array().of(
    object().shape({
      username: string().required('commons.chooseAnOptionFromTheList')
    })
  ),
  coupon: string().min(11, 'minimum11Characters').max(11, 'minimum11Characters')
});

export default schema;