import Firebase, { FirebaseContext } from 'server';
import { StateProvider } from 'context/store';
import { indexReducer } from 'context/reducers';
import initialState from 'context/initialState';
import Routes from 'routes';
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import { configTranslations } from 'translations';

i18next.use(LanguageDetector).init(configTranslations);

function App() {
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <StateProvider reducer={indexReducer} initialState={initialState}>
        <I18nextProvider i18n={i18next}>
          <Routes />
        </I18nextProvider>
      </StateProvider>
    </FirebaseContext.Provider>
  );
}

export default App;
