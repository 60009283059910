import { Container, Header, Grid, Segment, List, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './Subheader.module.css';

export default function Subheader({ link, nameLink, user, id }) {
  const { t } = useTranslation('app');
  const tournamentLink = id ? `/tournaments/${id}` : '/';
  return (
    <Container className={style.content}>
      <Segment basic>
        <Grid
          divided="vertically"
          stackable
          verticalAlign="bottom"
        >
          <Grid.Row>
            <Grid.Column className="text-center-xs" width={11}>
              <Header as='h2' color="black" inverted className={style.title}>
                <Header.Subheader className={style.info}>
                  {t('home.subtitle')}
                </Header.Subheader>
                {t('home.title')}
                <br />
                {t('home.eSports')}
              </Header>
              {user ? (
                <Link to={tournamentLink} className={`${style.mr40} ui button btn-primary btn-red mr-40`}>
                  {t('home.signUpForTournaments')}
                </Link>
              ) : (
                <Link to="/login" className={`${style.mr40} ui button btn-primary btn-red mr-40`}>
                  {t('home.signUpForTournaments')}
                </Link>
              )}
              <Link to={link} className={`${style.linkWhite} link-white light-white-color`}>
                <b>{nameLink}</b>
              </Link>
            </Grid.Column>
            <Grid.Column textAlign="right" className="text-center-xs text-center" width={5}>
              <Header as="h2" color="black" inverted>
                <Header.Subheader style={{ marginBottom: 10 }} className={style.sponsor}>
                  {t('home.sponsor')}:
                </Header.Subheader>
                <List horizontal>
                  <List.Item>
                    <Image src='/images/sponsors/redBull.png' />
                  </List.Item>
                  <List.Item>
                    <Image src='/images/sponsors/gameFather.png' />
                  </List.Item>
                  <List.Item>
                    <Image src='/images/sponsors/hem.png' />
                  </List.Item>
                </List>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  )
}