import { string, object, number, array } from 'yup';

const schema = object().shape({
  bestGames: number().typeError('commons.selectAnOption'),
  rules: string().required('commons.requiredField'),
  awards: object().shape({
    winner: string().required('commons.requiredField')
  })
});

export default schema;