import { Grid } from 'semantic-ui-react';
import Brands from 'components/Cards/Brands';
import Slider from 'react-slick';

export default function SectionBrands() {

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  const brands = [
    { logo: '/images/logos/warzone.png' },
    { logo: '/images/logos/halo.png' },
    { logo: '/images/logos/battlefield.png' },
    { logo: '/images/logos/freefire.png' },
    { logo: '/images/logos/fornite.png' },
    { logo: '/images/logos/valorant.png' },
    { logo: '/images/logos/apex.png' },
    { logo: '/images/logos/pubg.png' }
  ]

  return(
    <div>
      <Slider {...settings}>
        {brands.map((brand, index) => (
          <div key={index.toString()}>
            <Brands image={brand.logo} />
          </div>
        ))}
      </Slider>
    </div>
  )
}