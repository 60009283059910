import { Segment, Form, Button } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FORMAT, FORMAT_BRACKETS } from 'utils/constants';
import schema from './schemaPoints';

export default function TabPoints({ onSubmit, activeTabIndex, setActiveTabIndex, formData }) {
  const { t } = useTranslation('app');

  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  return (
    <Form size="large" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
      <Segment textAlign="left" color="teal" stacked padded="very">

        <Form.Group widths="equal">
          {formData && formData.tournamentFormat === 'killRace' ? (
            <Form.Field>
              <label>{t('tournaments.form.format')}</label>
              <Controller
                name="format"
                defaultValue={FORMAT[0].id}
                control={control}
                as={
                  <select>
                    {FORMAT.map(format => (
                      <option key={format.id} value={format.id}>{format.name}</option>
                    ))}
                  </select>
                }
              />
            </Form.Field>
          ) : (
            <Form.Field>
              <label>{t('tournaments.form.format')}</label>
              <Controller
                name="format"
                defaultValue={FORMAT_BRACKETS[0].id}
                control={control}
                as={
                  <select>
                    {FORMAT_BRACKETS.map(format => (
                      <option key={format.id} value={format.id}>{format.name}</option>
                    ))}
                  </select>
                }
              />
            </Form.Field>
          )}
          {formData && formData.tournamentFormat === 'killRace' && (
            <>
              <Form.Field>
                <label>{t('tournaments.form.fromKd')}</label>
                <input
                  name="fromKd"
                  placeholder={t('tournaments.form.fromKd')}
                  maxLength="3"
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="fromKd"
                  render={({ message }) => <small className="red">{t(message)}</small>}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('tournaments.form.topKd')}</label>
                <input
                  name="topKd"
                  placeholder={t('tournaments.form.topKd')}
                  maxLength="3"
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="topKd"
                  render={({ message }) => <small className="red">{t(message)}</small>}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('tournaments.form.maxGroupKd')}</label>
                <input
                  name="maxGroupKd"
                  placeholder={t('tournaments.form.maxGroupKd')}
                  maxLength="3"
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="maxGroupKd"
                  render={({ message }) => <small className="red">{t(message)}</small>}
                />
              </Form.Field>
            </>
          )}
        </Form.Group>
        {formData && formData.tournamentFormat === 'killRace' && (
          <>
            <h3 className="text-center">{t('tournaments.form.typeOfPoints')}</h3>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>{t('tournaments.form.kill')}</label>
                <input
                  name="typePoints.kill"
                  placeholder={t('tournaments.form.points')}
                  maxLength="2"
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.kill"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '1', maxPoint: '10' })}</small>}
                />
              </Form.Field>
              <Form.Field required>
                <label>{t('tournaments.form.win')}</label>
                <input
                  name="typePoints.win"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.win"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '1', maxPoint: '100' })}</small>}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>{t('tournaments.form.top2')}</label>
                <input
                  name="typePoints.top2"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  ref={register}
                  defaultValue={0}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.top2"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '0', maxPoint: '100' })}</small>}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('tournaments.form.top3')}</label>
                <input
                  name="typePoints.top3"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  defaultValue={0}
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.top3"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '0', maxPoint: '100' })}</small>}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('tournaments.form.top4')}</label>
                <input
                  name="typePoints.top4"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  defaultValue={0}
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.top4"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '0', maxPoint: '100' })}</small>}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('tournaments.form.top5')}</label>
                <input
                  name="typePoints.top5"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  defaultValue={0}
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.top5"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '0', maxPoint: '100' })}</small>}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t('tournaments.form.top10')}</label>
                <input
                  name="typePoints.top10"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  defaultValue={0}
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.top10"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '0', maxPoint: '100' })}</small>}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('tournaments.form.top15')}</label>
                <input
                  name="typePoints.top15"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  defaultValue={0}
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.top15"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '0', maxPoint: '100' })}</small>}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('tournaments.form.top20')}</label>
                <input
                  name="typePoints.top20"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  defaultValue={0}
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.top20"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '0', maxPoint: '100' })}</small>}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('tournaments.form.top26')}</label>
                <input
                  name="typePoints.top26"
                  placeholder={t('tournaments.form.points')}
                  maxLength="3"
                  defaultValue={0}
                  ref={register}
                />
                <ErrorMessage
                  errors={errors}
                  name="typePoints.top26"
                  render={({ message }) => <small className="red">{t(message, { minPoint: '0', maxPoint: '100' })}</small>}
                />
              </Form.Field>
            </Form.Group>
          </>
        )}

        <br />
        <div className="text-center">
          <Button
            type="button"
            color="teal"
            content={t('tournaments.form.back')}
            icon="arrow left"
            size="large"
            labelPosition="left"
            onClick={() => setActiveTabIndex(activeTabIndex - 1)}
          />
          <Button
            color="teal"
            content={t('tournaments.form.next')}
            icon="arrow right"
            size="large"
            labelPosition="right"
          />
        </div>   
      </Segment>
    </Form>
  )
}