import { Container, Header } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import Slider from 'react-slick';
import CardTournamentsUpcoming from 'components/Cards/CardTournaments/CardTournamentsUpcoming';
import {useTranslation} from "react-i18next";

export default function ListUpcomingTournaments({ list }) {
  const { t } = useTranslation('app');

  const listUpcoming = () => {
    return list.filter(tournament => {
      let dateTournament = DateTime.fromJSDate(new Date(tournament.startDate.toDate()), { zone: tournament.zone });
      if (dateTournament.ts > new Date().getTime()) {
        return { ...tournament }
      }
    })
  }

  const settings = {
    dots: false,
    infinite: listUpcoming().length > 2 ? true : false,
    speed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section style={{ backgroundColor: '#E2E2E2', padding: '50px 0 80px' }}>
      <Container>
        <Header as="h3" style={{ margin: '15px 0 0 20px' }}>
          <Header.Subheader className="subtitle-color">
            {t('next.subtitle')}
          </Header.Subheader>
          <span className="title-color">{t('next.nextTournaments')}</span>
        </Header>
        <br />
        {listUpcoming().length > 0 && (
          <Slider {...settings}>
            {listUpcoming().map(tournament => {
              return (
                <CardTournamentsUpcoming key={tournament.id} {...tournament} />
              )
            })} 
          </Slider>
        )}
      </Container>
    </section>
  )
}