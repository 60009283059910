import { Container, Grid, Image, List, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { GAMES } from 'utils/constants';
import Header from 'components/Layouts/Layout/Header';
import style from './Subheader.module.css';

export default function SubheaderProfile({ data }) {
  const { t } = useTranslation('app');
  const getPlatform = () => {
    const pt = GAMES.filter(x => x.key === data.type);
    return pt ? pt[0].name : '-'
  }
  return(
    <Header height="410px" background="linear-gradient(137deg, #30B9D1 0%, #279AAF 100%) 0% 0%">
      <div className={style.containerSub}>
        <Container>
          <label className={style.platform}>
            {getPlatform()}
          </label>
          <div className={style.content}>
            <Grid columns={2} verticalAlign="middle" stackable className="text-center-xs">
              <Grid.Row>
                <Grid.Column>
                  <Grid verticalAlign="middle" stackable>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <Image src="/images/92.svg" className={style.logo} />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <h2 className={style.welcome}>
                          {t('profile.welcome')}
                        </h2>
                        <p className={style.modality}>
                          {t('profile.modality')} {getPlatform()}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <List horizontal relaxed style={{ marginBottom: 20, width: '100%' }}>
                    <List.Item className={style.listItem}>
                      <p className={style.item}>
                        {data.lifetime.mode.br.properties.gamesPlayed}
                      </p>
                      <p className={style.subItem}>
                        {t('profile.games')}
                      </p>
                    </List.Item>
                    <List.Item className={style.listItem}>
                      <p className={style.item}>
                        {data.lifetime.mode.br.properties.kills}
                      </p>
                      <p className={style.subItem}>
                        {t('profile.kills')}
                      </p>
                    </List.Item>
                    <List.Item className={style.listItem}>
                      <p className={style.item}>
                        {parseFloat(data.lifetime.mode.br.properties.kdRatio).toPrecision(2)}
                      </p>
                      <p className={style.subItem}>
                        {t('profile.kdRatio')}
                      </p>
                    </List.Item>
                  </List>
                  <List horizontal relaxed>
                    <List.Item className={style.listItem}>
                      <p className={style.item}>
                        {data.lifetime.mode.br.properties.deaths}
                      </p>
                      <p className={style.subItem}>
                        {t('profile.deaths')}
                      </p>
                    </List.Item>
                    <List.Item className={style.listItem}>
                      <p className={style.item}>
                        {parseFloat(data.weekly.mode.br_all.properties.kdRatio).toPrecision(2)}
                      </p>
                      <p className={style.subItem}>
                        {t('profile.kd7Days')}
                      </p>
                    </List.Item>
                    <List.Item className={style.listItem}>
                      <p className={style.item}>
                        {data.lifetime.mode.br.properties.wins}
                      </p>
                      <p className={style.subItem}>
                        {t('profile.won')}
                      </p>
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Image src="/images/profile/player.png" className={style.player} />
          </div>
          <div className="text-right text-center-xs" style={{ marginTop: 10 }}>
            <Button className={style.edit} href="/app/profile/edit">
              {t('profile.editProfile')}
            </Button>
          </div>
        </Container>
      </div>
    </Header>
  )
}