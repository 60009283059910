import { string, object, array } from 'yup';

const schema = object().shape({
  organizers: array().of(
    object().shape({
      organizerUrl: string().url('commons.mustBeAvalidUrl')
    })
  ),
  sponsors: array().of(
    object().shape({
      sponsorUrl: string().url('commons.mustBeAvalidUrl')
    })
  )
});

export default schema;