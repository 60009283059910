import { MESSAGE, GET_DATA_USER, LOADING_GET_DATA_USER } from './constants';

export const message = async (dispatch, message) => {
  dispatch({ type: MESSAGE, message });
}

export const getDataUser = (dispatch, firebase, uid) => {
  dispatch({ type: LOADING_GET_DATA_USER, loadingUser: true })

  return firebase.db.collection('users')
    .doc(uid)
    .get()
    .then((doc) => {
      dispatch({
        type: GET_DATA_USER,
        userData: doc.data()
      })
      dispatch({ type: LOADING_GET_DATA_USER, loadingUser: false })
    }).catch((error) => {
      console.log('Error getting document:', error);
      dispatch({ type: LOADING_GET_DATA_USER, loadingUser: false })
    });
}

export const clearUser = dispatch => {
  dispatch({
    type: GET_DATA_USER,
    userData: null
  })
}