import style from './PersonalData.module.css';

export default function PersonalData({ name, email, username, platform }) {
  return(
    <>
      <h3 className={style.name}>
        {name}
      </h3>
      <small className={style.email}>{email}</small>
      <h2 className={style.username}>
        {username}
        <p className={style.platform}>{platform}</p>
      </h2>
    </>
  )
}