import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Loader } from 'semantic-ui-react';
import Autosuggest from 'react-autosuggest';
import { Controller, useFormContext } from 'react-hook-form';
import { FirebaseContext } from 'server';
import { useTranslation } from 'react-i18next';
import FloatingMessage from 'components/FloatingMessage';
import { debounce } from 'lodash';

const getSuggestions = async (value, firebase, platform) => {
  const escapedValue = value.trim();
  if (escapedValue === "") {
    return [];
  }

  if (value.length > 2) {
    let userNames = firebase.functions.httpsCallable('getUsernames');
    const req = userNames({
      username: escapedValue,
      platform
    });
    const data = await req;
    return typeof data.data === 'string' ? [] : data.data || [];
  }
  return []
}

function renderSuggestion(suggestion) {
  return <span>{suggestion.username}</span>;
}
export default function InputAutosuggest({
  name, control, defaultValue, placeholder, platform, clearErrors
}) {
  const [valueInput, setValueInput] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const firebase = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const methods = useFormContext();
  const [noUser, setNoUser] = useState(null);
  const { t } = useTranslation('app');

  const loadSuggestions = (value, platform) => {
    setLoading(true);
    setTimeout(async () => {
      const sugg = await getSuggestions(value, firebase, platform);
      if (value.length > 2) {
        setSuggestion(sugg);
        setLoading(false);
        sugg.length <= 0 ? setNoUser(t('commons.messageNoUserFound')) : setNoUser(null);
      } else {
        setLoading(false);
      }
    }, 100);
  }

  const debounceLoadData = useCallback(debounce(loadSuggestions, 1000), []);
  
  useEffect(() => {
    setValueInput("");
    control.setValue(name, "");
  }, [platform])

  useEffect(() => {
    if (defaultValue) {
      setValueInput(defaultValue);
      control.setValue(name, defaultValue);
    }
  }, [defaultValue])

  const onChange = (event, { newValue, method }) => {
    setValueInput(newValue);
    newValue !== control.getValues(name) && control.setValue(name, "");
    return newValue;
  };

  const onSuggestionsFetchRequested = ({ value }) => debounceLoadData(value, platform);

  const onSuggestionsClearRequested = () => {
    setSuggestion([]);
  };

  const getSuggestionValue = suggestion => {
    if (suggestion.username) {
      control.setValue(name, suggestion.username);
      clearErrors([name])
      return suggestion.username;
    }
  };

  const inputProps = {
    placeholder,
    value: valueInput,
    onChange
  };

  const classes = {
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 3,
      left: 0,
      right: 0,
      maxHeight: 250,
      overflow: 'auto',
      backgroundColor: 'white',
      border: '1px solid #dededf',
      padding: '0 5px'
    },
    suggestionsList: {
      paddingLeft: 0,
    },
    suggestion: {
      display: 'block',
      padding: 5,
      color: '#464242',
      fontSize: '0.97rem'
    }
  };

  return (
    <div className="relative">
      {loading && <Loader active inline size='tiny' style={{ position: 'absolute', right: '15px', left: 'auto', top: '11px' }} />}
      <Controller
        as={
          <Autosuggest
            suggestions={suggestion}
            theme={classes}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
          />
        }
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
      {noUser && (
        <FloatingMessage
          message={noUser}
          isOpen={!!noUser}
          close={() => setNoUser(null)}
        />
      )}
    </div>
  );
}
