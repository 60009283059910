import { useState } from 'react';
import { Table, Button, Icon, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import randomColor from 'randomcolor'; 
import PlayerDataModal from 'components/Modals/PlayersDataModal';
import AddParticipants from 'components/Modals/Participants/Participants';
import style from './TeamTable.module.css';

export default function TeamTable({ data, bestGames, format, user, tournamentFormat }) {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [team, setTeam] = useState(null);
  const { t } = useTranslation('app');

  const handleModal = (data) => {
    setOpen(!open);
    setTeam(data);
  }

  const handleModalEdit = (data) => {
    setOpenEdit(true),
    setTeam(data)
  }

  const socialMedia = (sm) => {
    if (sm && sm.fbSocial) {
      return (
        <a href={sm.fbSocial} target="_blank" className={style.social}>
          <img src="/images/social/facebook.svg" />
        </a>
      )
    }
    else if (sm && sm.twitchSocial) {
      return (
        <a href={sm.twitchSocial} target="_blank" className={style.social}>
          <img src="/images/social/twitch.svg" />
        </a>
      )
    }
    else if (sm && sm.ytSocial) {
      return (
        <a href={sm.ytSocial} target="_blank" className={style.social}>
          <img src="/images/social/youtube.svg" />
        </a>
      )
    }
    else if (sm && sm.otherSocial) {
      return (
        <a href={sm.otherSocial} target="_blank" className={style.social}>
          <img src="/images/social/other.svg" />
        </a>
      )
    }
  }

  return (
    <>
      <Table striped selectable className={style.tableTeam}>
        <Table.Header className={style.hiddenXs}>
          <Table.Row>
            <Table.HeaderCell className="text-center">
              {t('tournament.table.top')}
            </Table.HeaderCell>
            <Table.HeaderCell className={style.team}>
              {t('tournament.table.team')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('tournament.table.points')}
            </Table.HeaderCell>
            <Table.HeaderCell className={style.hiddenSm}>
              {t('tournament.table.participants')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('tournament.table.detail')}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className="text-center-xs">
          {data.map((team, index) => (
            <Table.Row key={team.id}>
              <Table.Cell className="text-center">
                <span className={style.top}>
                  {index + 1}
                </span>
              </Table.Cell>
              <Table.Cell>{team.teamName}</Table.Cell>
              <Table.Cell>
                <div className={style.circlePoints}>
                  <img src="/images/icons/trophys.svg" />
                  <span>
                    {team.totalPoints ? team.totalPoints: 0}
                  </span>
                </div>
              </Table.Cell>
              <Table.Cell className={style.hiddenSm}>
                {team.participants.length > 0 && team.participants.map((player, index) => (
                  <Popup content={player.username} key={index.toString()} position='bottom center' trigger={
                    <div className={style.circleUser} style={{ background: randomColor({ luminosity: 'dark', alpha: 0.5, format: 'hsla' }) }}>
                      {socialMedia && socialMedia(player.socialMedia)}
                      {player.username ? player.username.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() : '-'}
                    </div>
                  } />
                ))}
              </Table.Cell>
              <Table.Cell>
                <Button
                  className="btn-primary btn-red"
                  onClick={() => handleModal(team)}
                >
                  {t('tournament.table.detail')}
                </Button>
              </Table.Cell>
              <Table.Cell>
                {user && user.userId === team.teamCaptain && (
                  <Button
                    icon
                    className="ui button btn-primary"
                    onClick={() => handleModalEdit(team)}
                  >
                    <Icon name="edit outline" />
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {open && (
        <PlayerDataModal
          open={open}
          setOpen={setOpen}
          idTournament={team.idTournament}
          id={team.id}
          teamName={team.teamName}
          bestGames={bestGames}
        />
      )}
      {openEdit && (
        <AddParticipants
          open={openEdit}
          setOpen={setOpenEdit}
          format={format}
          username={team && team.participants[0].username}
          platform={team && team.participants[0].platform}
          idTournament={team.idTournament}
          isPrivate={false}
          userId={team.teamCaptain? team.teamCaptain : user.userId}
          teamName={team.teamName}
          teamId={team.id}
          playersEdit={team.participants}
          tournamentFormat={tournamentFormat}
        />
      )}
    </>
  )
}