import LayoutOut from 'components/Layouts/LayoutOut';
import ResetPasswordForm from 'components/Forms/ResetPasswordForm';
import { useTranslation } from 'react-i18next';

export default function ResetPassword() {
  const { t } = useTranslation('app');
  return (
    <LayoutOut
      title={t('session.recoverPassword')}
      icon="address card outline"
    >
      <ResetPasswordForm />
    </LayoutOut>
  )
}