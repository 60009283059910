import { useContext, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FirebaseContext } from 'server';
import { useStateValue } from 'context/store';
import { editUser } from 'context/session/actions';
import { message as closeMessage } from 'context/commons/actions';
import SignupForm from 'components/Forms/SignupForm';
import Loading from 'components/Loading';
import FloatingMessage from 'components/FloatingMessage';

export default function EditProfile() {
  const { t } = useTranslation('app');
  const firebase = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [{commons: { userData }, commons: { message } }, dispatch] = useStateValue();

  const onSubmit = async values => {
    setLoading(true);
    const callback = await editUser(dispatch, firebase, values, userData.userId);
    callback && setLoading(false);
  }

  return (
    <>
      {loading && <Loading /> }
      <Grid 
        textAlign="center"
        verticalAlign="middle"
        container
        style={{ padding: '30px 0',  minHeight: 'calc(100vh - 150px)' }}
      >
        <Grid.Column style={{ maxWidth: '550px' }}>
          <Header as="h2" textAlign="left">
            {t('commons.editInformation')}
          </Header>
          {userData && (
            <SignupForm
              submit={onSubmit}
              user={userData}
            />
          )}
        </Grid.Column>
      </Grid>
      {message && (
        <FloatingMessage
          message={message}
          isOpen={!!message}
          close={() => closeMessage(dispatch, null)}
        />
      )}
    </>
  )
}