import { Header, Container, Image, List } from 'semantic-ui-react';
import style from './ContactUs.module.css';
import {useTranslation} from "react-i18next";

export default function ContactUs() {
  const { t } = useTranslation('app');
  return(
    <section className={style.contactUs}>
      <Container textAlign="center">
        <Header as="h2">
          <Header.Subheader>
            {t('contact.contact')}
          </Header.Subheader>
          {t('contact.wishContact')}
        </Header>
        <p className={style.text}>
          {t('contact.destContact')}
        </p>
        <b>{t('contact.emailContact')}</b>
        <br />
        <a className={style.email} href="mailto:equipo@hemgaming.mx?Subject=92 Tournaments Official">{t('contact.hemContact')}</a>
        <br /><br />
        <p className={style.text2}>{t('contact.orWriteUsAt')}</p>
        <List verticalAlign="middle" horizontal className={style.list}>
          <List.Item className={style.item}>
            <Image src='/images/icons/facebook-messenger-icon.png' />
            <List.Content>
              <List.Header as='a' href="https://www.facebook.com/hemgaming.mx" target="_blank">
                <b className={style.social}>Facebook</b>
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item className={style.item}>
            <Image src='/images/icons/instagram-icon.png' />
            <List.Content>
              <List.Header as='a' href="https://www.instagram.com/hemgaming.mx" target="_blank">
                <b className={style.social}>Instagram</b>
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item className={style.item}>
            <Image src='/images/icons/twitter-icon.png' />
            <List.Content>
              <List.Header as='a' href="https://twitter.com/HemGamingMx" target="_blank">
                <b className={style.social}>Twitter</b>
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
        <div className="text-center">
          <Image src="/images/hem_negro.png" style={{ display: 'inline-block' }} />
        </div>
      </Container>
    </section>
  )
}