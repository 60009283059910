import { Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useStateValue } from 'context/store';
import HeaderInternals from 'components/Layouts/Layout/Header';
import Subheader from '../components/Subheaders/Subheader';
import FormTournaments from './Form';

export default function NewTournament() {
  const { t } = useTranslation('app');
  const [{commons: { userData }}, dispatch] = useStateValue();

  return (
    <>
      <HeaderInternals height="30vh">
        <Subheader
          link="/"
          nameLink={t('tournaments.tournaments')}
          user={userData ? userData : null}
        />
      </HeaderInternals>
      <br /><br />
      <Container>
        <FormTournaments />
        <br /><br />
      </Container>
    </>
  )
}