import { string, object, number } from 'yup';

const schema = object().shape({
  fromKd: string().matches(/^\d*\.{1}\d*$/, 'commons.itMustBeADecimal').min(3, 'commons.exampleDecimal'),
  topKd: string().matches(/^\d*\.{1}\d*$/, 'commons.itMustBeADecimal').min(3, 'commons.exampleDecimal'),
  maxGroupKd: string().matches(/^\d*\.{1}\d*$/, 'commons.itMustBeADecimal').min(3, 'commons.exampleDecimal'),
  typePoints: object().shape({
    kill: number().typeError('commons.pointInterval').min(1, 'commons.minimumPoint').max(10, 'commons.maximumPoints'),
    win: number().typeError('commons.youMustIndicateThePoints').min(1, 'commons.minimumPoint').max(100, 'commons.maximumPoints'),
    top2: number().typeError('commons.youMustIndicateThePoints').min(0, 'commons.minimumPoint').max(100, 'commons.maximumPoints'),
    top3: number().typeError('commons.youMustIndicateThePoints').min(0, 'commons.minimumPoint').max(100, 'commons.maximumPoints'),
    top4: number().typeError('commons.youMustIndicateThePoints').min(0, 'commons.minimumPoint').max(100, 'commons.maximumPoints'),
    top5: number().typeError('commons.youMustIndicateThePoints').min(0, 'commons.minimumPoint').max(100, 'commons.maximumPoints'),
    top10: number().typeError('commons.youMustIndicateThePoints').min(0, 'commons.minimumPoint').max(100, 'commons.maximumPoints'),
    top15: number().typeError('commons.youMustIndicateThePoints').min(0, 'commons.minimumPoint').max(100, 'commons.maximumPoints'),
    top20: number().typeError('commons.youMustIndicateThePoints').min(0, 'commons.minimumPoint').max(100, 'commons.maximumPoints'),
    top26: number().typeError('commons.youMustIndicateThePoints').min(0, 'commons.minimumPoint').max(100, 'commons.maximumPoints')
  })
});

export default schema;