export const PLATFORMS = [
  { id: 'psn', name: 'psn' },
  { id: 'battle', name: 'Battle' },
  { id: 'xbl', name: 'Xbox' }
]

export const FORMAT = [
  { id: 'brsolo', name: 'Solo', total: 1 },
  { id: 'brduos', name: 'Duos', total: 2 },
  { id: 'brtrios', name: 'Trios', total: 3 },
  { id: 'brquads', name: 'Quads', total: 4}
]

export const GAMES = [
  { id: 'warzone', key: 'wz', name: 'Warzone', image: '/images/tournaments/warzone.jpeg', logo: '/images/logos/warzone.png' },
  { id: 'fortnite', key: 'fnt', name: 'Fortnite' },
  { id: 'apex', key: 'apx', name: 'Apex' },
  { id: 'halo', key: 'hl', name: 'Halo' }
]

export const FORMAT_TOURNAMENT = [
  { id: 'killRace', name: 'Kill race' },
  { id: 'brackets', name: 'Brackets' }
]

export const FORMAT_BRACKETS = [
  { id: '1v1', name: '1v1', total: 1 },
  { id: '2v2', name: '2v2', total: 2 }
]