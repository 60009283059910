import { useTranslation } from 'react-i18next';

import LayoutOut from 'components/Layouts/LayoutOut';
import LoginForm from 'components/Forms/LoginForm';

const Login = () => {
  const { t } = useTranslation('app');

  return (
    <LayoutOut
      title={t('session.login')}
      icon="address card outline"
    >
      <LoginForm />
    </LayoutOut>
  )
}

export default Login;