import { useState, useContext } from 'react';
import { Menu, Sidebar, Container, Icon, Checkbox, Dropdown } from 'semantic-ui-react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FirebaseContext } from 'server';
import { logout } from 'context/session/actions';
import { useDispatch } from 'context/store';
import Language from 'components/Language';
import style from './TopMenu.module.css';

const TopMenu = ({ user }) => {
  const firebase = useContext(FirebaseContext);
  const { t } = useTranslation('app');
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const close = () => logout(dispatch, firebase, history);

  const ListMenu = () => (
    <>
      <Menu.Menu position="right" className={style.containerMenu}>
        {user ? (
          <>
            <Dropdown item text={t('menu.tournaments')}>
              <Dropdown.Menu>
                <Dropdown.Item href="/tournaments">
                  {t('menu.tournaments')}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/app/tournaments/new">
                  {t('menu.newTournament')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown item text={t('menu.profile')}>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/app/profile">
                  {user && t('commons.myProfile')}
                </Dropdown.Item>
                <Dropdown.Item onClick={close}>
                  {t('menu.logout')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item>
              <Language textColor='rgba(255,255,255,.8)' />
            </Menu.Item>
            <Menu.Item>
              <div className={style.circleUser}>
                {user.name ? user.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() : '-'}
              </div>
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item link href="/login">
              {t('menu.login')}
            </Menu.Item>
            <Menu.Item link href="/signup">
              {t('menu.signup')}
            </Menu.Item>
            <Menu.Item>
              <Language textColor='rgba(255,255,255,.8)' />
            </Menu.Item>
          </>
        )}
      </Menu.Menu>
   </>
  )

  return (
    <>
      <Sidebar
        as={Menu}
        icon="labeled"
        animation="overlay"
        inverted
        vertical
        visible={visible}
        style={{ background: 'rgb(0, 157, 178)' }}
        onHide={() => setVisible(false)}
        width="thin"
        className={style.containerMenu}
      >
        <Menu.Item link href="/">
          <img src="/logo.png" className={style.logoResponsive} />
        </Menu.Item>
        <Menu.Item link href="/tournaments">
          {t('menu.tournaments')}
        </Menu.Item>
        <Menu.Item link href="/app/tournaments/new">
          {t('menu.newTournament')}
        </Menu.Item>
        {user ? (
          <>
            <Menu.Item link href="/app/profile">
              {t('menu.myProfile')}
            </Menu.Item>
            <Menu.Item onClick={close}>
              {t('menu.logout')}
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item link href="/login">
              {t('menu.login')}
            </Menu.Item>
            <Menu.Item link href="/signup">
              {t('menu.signup')}
            </Menu.Item>
          </>
        )}
      </Sidebar>
      <Menu inverted fixed="top" className={style.hideMd} style={{ background: 'linear-gradient(137deg, rgb(48, 185, 209) 0%, rgb(39, 154, 175) 100%) 0% 0%' }}>
        <Menu.Item link href="/" className={style.linkPrincipal}>
          <img src='/logo.png' className={style.logo} />
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Checkbox
              className={style.check}
              checked={visible}
              label={{ children: <Icon name="content" /> }}
              onChange={(e, data) => setVisible(data.checked)}
            />
          </Menu.Item>
          <Menu.Item>
            <Language textColor='rgba(255,255,255,.8)' />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      
      <Menu borderless stackable fixed="top" className={style.hideXs} inverted style={{ background: 'linear-gradient(137deg, rgb(48, 185, 209) 0%, rgb(39, 154, 175) 100%) 0% 0%' }}>
        <Container className={style.menuStyle}>
          <Menu.Item link href="/" className={style.linkPrincipal}>
            <img src="/logo.png" className={style.logo} />
          </Menu.Item>
          {ListMenu()}
        </Container>
      </Menu>
    </>
  )
}

export default TopMenu;