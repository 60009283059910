import { useContext, useState } from 'react';
import { Button, Form, Header, Message, Segment, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { FirebaseContext } from 'server';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { resetPassword } from 'context/session/actions';
import Loading from 'components/Loading';
import FloatingMessage from 'components/FloatingMessage';
import { schema } from './schema';

const ResetPasswordForm = () => {
  const [open, setOpen] = useState(false);
  const firebase = useContext(FirebaseContext);
  const { t } = useTranslation('app');
  const [{ session: { loading }, commons: { message } }, dispatch] = useStateValue();

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const onSubmit = async values => {
    const callback = await resetPassword(dispatch, firebase, values);
    callback && setOpen(true)
  }
  
  return (
    <>
      <Form size="large" onSubmit={handleSubmit(onSubmit)} noValidate>
        {loading && <Loading />}

        <Segment padded textAlign="left">
          <Header as="h3" color="grey" textAlign="left">
            {t('session.enterYourEmail')}
          </Header>
          <br />

          <Form.Field required style={{ margin: '10px 0 25px' }} error={!!errors.email}>
            <input
              type="email"
              name="email"
              autoComplete="current-email"
              placeholder={`${t('session.email')} *`}
              ref={register}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
          <Button color="teal" fluid size="large">
            {t('session.recoverPassword')}
          </Button>
        </Segment>
      </Form>

      <Message>
        {t('session.doYouAlreadyHaveAUser')} <Link to="/login">{t('session.login')}</Link>
      </Message>
      {message && (
        <FloatingMessage
          message={message}
          isOpen={!!message}
          close={() => closeMessage(dispatch, null)}
        />
      )}
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='small'
      >
      <Modal.Header style={{ padding: '30px' }}>
        {t('session.aLinkHasBeenSentToYourEmail')}
      </Modal.Header>
      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          {t('session.continue')}
        </Button>
      </Modal.Actions>
    </Modal>
    </>
  )
}

export default ResetPasswordForm;