export default function Header({ children, height, background, blur }) {
  return(
    <header
      style={{ 
        minHeight: height, 
        background: background ? background : 'linear-gradient(137deg, #30B9D1 0%, #279AAF 100%) 0% 0%',
        backdropFilter: blur
      }}
    >
      {children}
    </header>
  )
}