import { Container } from 'semantic-ui-react';
import style from './Sponsors.module.css';

export default function Sponsors({ sponsors }) {
  return (
    <Container className={style.containerSponsors}>
      {sponsors.map((sponsor, index) => {
        if(sponsor.sponsorImage) {
          return (
            <a target="_blank" key={index.toString()} href={sponsor.sponsorUrl ? sponsor.sponsorUrl : ''} className={style.contImageSponsor}>
              <img src={sponsor.sponsorImage} className={style.imageSponsor} /> 
            </a>
          )
        } else {
          return (
            <a target="_blank" key={index.toString()} href={sponsor.sponsorUrl ? sponsor.sponsorUrl : ''} className={style.link}>
              <span>{sponsor.fullName}</span>
            </a>
          )
        }
      })}
    </Container>
  )
}