import { useContext, useEffect } from 'react';
import { Modal, Button, Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FirebaseContext } from 'server';
import { useStateValue } from 'context/store';
import { getMatches } from 'context/tournaments/actions';
import style from './PlayersDataModal.module.css';

export default function PlayerDataModal({
  open, setOpen, idTournament, id, teamName, bestGames
}) {
  const { t } = useTranslation('app');
  const firebase = useContext(FirebaseContext);
  const [{tournaments: { matches }}, dispatch] = useStateValue();

  useEffect(() => {
    getMatches(firebase, dispatch, idTournament, id, bestGames);
  }, [idTournament, id]);

  return(
    <Modal
       onClose={() => setOpen(false)}
       onOpen={() => setOpen(true)}
       open={open}
     >
      <Modal.Header className="text-center uppercase">
        <b className={style.nameTeam}>{teamName}</b>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ padding: '50px 30px' }}>
          <Grid columns={bestGames === 0 ? 'equal' : matches.length === 0 ? 'equal' : parseInt(bestGames)} stackable>
            <Grid.Row>
              {matches && matches.length > 0 ? (
                matches.map((match, index) => {
                  return (
                    <Grid.Column key={match.id}>
                      <h4 className={style.bestMatch}>{`${t('tournament.bestGame')} # ${index + 1}`}</h4>
                      <Segment padded>
                        {Object.entries(match.kills).sort((a,b) => b[1]-a[1]).map(([key, val]) => {
                          return (
                            <p key={key} className={style.username}>
                              {key === 'teamPlacement' ? t('tournaments.position') : key} <span style={{ float: 'right'}}>{val}</span>
                            </p>
                          )
                        })}
                        <p className={style.total}>
                          <b>{t('commons.total')}: </b>
                          <b style={{ float: 'right' }}>{match.totalPoints}</b>
                        </p>
                      </Segment>
                    </Grid.Column>
                  )
                })
              ): (
                <Grid.Column><h3 className="text-center">Aún no hay datos</h3></Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Modal.Description>
        <div className="text-right" style={{ padding: '10px' }}>
          <Button className="btn-primary btn-red" onClick={() => setOpen(false)}>
            Cerrar
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}