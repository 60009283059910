import {
  FORM_DATA,
  LOADING,
  GET_MATCHES,
  GET_TOURNAMENT,
  LIST_TOURNAMENTS,
  LIST_ALL_TOURNAMENTS,
  SPECIAL_LIST_TOURNAMENTS,
  GET_TEAM
} from './constants';

export const tournamentForm = (dispatch, data) => {
  dispatch({
    type: FORM_DATA,
    form: data
  });
};

const getCoupon = async (firebase, id, coupon) => {
  const generateCoupon = coupon.generate({
    prefix: 'GF-',
    postfix: '-92',
    length: 5,
    count: 1000
  });
  generateCoupon.length > 0 && generateCoupon.map(cp => {
    return new Promise((resolve, reject) => {
      firebase.db
      .collection('coupons')
      .doc(id)
      .collection('list')
      .doc(cp)
      .set({
        coupon: cp
      }).then(() => {
        resolve({ status: true });
      });
    });
  })
}

export const newTournament = async (dispatch, firebase, values, uid, coupon) => {
  dispatch({ type: LOADING, loading: true });
  const { startDate } = values;
  const data = {
    ...values,
    startDate: firebase.firestore.Timestamp.fromDate(new Date(startDate)),
    createdDate: firebase.firestore.Timestamp.fromDate(new Date()),
    image: '',
    organizers: null,
    sponsors: null,
    userOwner: uid,
    typePoints: values.typePoints.win ? { ...values.typePoints } : null
  }

  return new Promise((resolve, reject) => {
    firebase.db
    .collection('tournaments')
    .add({
      ...data
    })
    .then((doc) => {
      if (values.image) {
        uploadFiles(firebase, values.image[0], uid, doc.id, 'image', null);
      }
      if (values.organizers && values.organizers.length > 0) {
        values.organizers.map((organizer) => {
          return uploadFiles(firebase, organizer.organizerImage[0] ? organizer.organizerImage[0] : null , uid, doc.id, 'imageOrg', { ...organizer });
        })
      }
      if (values.sponsors && values.sponsors.length > 0) {
        values.sponsors.map((sponsor) => {
          return uploadFiles(firebase, sponsor.sponsorImage[0] ? sponsor.sponsorImage[0] : null , uid, doc.id, 'imageSponsor', { ...sponsor });
        })
      }
      values.isPrivate && getCoupon(firebase, doc.id, coupon);
      dispatch({ type: LOADING, loading: false });
      resolve({ status: true });
    })
    .catch((error) => {
      dispatch({ type: LOADING, loading: false });
      reject(error);
    })
  });
}

const uploadFiles = (firebase, file, uid, doc, type, data) => {
  if (file) {
    const storageRef = firebase.storage.ref(`medias/tournaments/${doc}/${file.name}`);
    const task = storageRef.put(file);
    task.on('state_changed', (snapshot) => {
    }, (error) => {
      console.error(error.message)
    }, () => {
      firebase.storage.ref(`medias/tournaments/${doc}`).child(file.name).getDownloadURL()
      .then(async fireBaseUrl => {
        type === 'image' && await updateTournamentImage(firebase, doc, fireBaseUrl); 
        type === 'imageOrg' && await updateTournamentImageOrg(firebase, doc, fireBaseUrl, data);
        type === 'imageSponsor' && await updateTournamentImageSponsor(firebase, doc, fireBaseUrl, data);
      })
    })
  } else if (type === 'imageOrg'){
    updateTournamentImageOrg(firebase, doc, '', data);
  }
  else if (type === 'imageSponsor'){
    updateTournamentImageSponsor(firebase, doc, '', data);
  }
}

const updateTournamentImage = (firebase, doc, image) => {
  return firebase.db
    .collection('tournaments')
    .doc(doc)
    .update({
      image: image
    }).then(() => {
      return true
    })
}

const updateTournamentImageOrg = (firebase, doc, image, data) => {
  return firebase.db
    .collection('tournaments')
    .doc(doc)
    .update({
      organizers: firebase.firestore.FieldValue.arrayUnion({
        ...data,
        organizerImage: image ? image : ''
      })
    }).then(() => {
      return true
    })
}

const updateTournamentImageSponsor = (firebase, doc, image, data) => {
  return firebase.db
    .collection('tournaments')
    .doc(doc)
    .update({
      sponsors: firebase.firestore.FieldValue.arrayUnion({
        ...data,
        sponsorImage: image ? image : ''
      })
    }).then(() => {
      return true
    })
}

export const listTournaments = async (firebase, dispatch) => {
  const data = [];
  return firebase.db
    .collection('tournaments')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data()});
      });
      dispatch({
        type: LIST_TOURNAMENTS,
        list: data
      });
    });
}

export const specialListTournaments = async (firebase, dispatch) => {
  const data = [];
  return firebase.db
    .collection('tournaments')
    .where('special', '==', true)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data()});
      });
      dispatch({
        type: SPECIAL_LIST_TOURNAMENTS,
        specialList: data
      });
    });
}

//Detail tournament
export const detailTournament = (firebase, dispatch, id) => {
  return firebase.db
    .collection('tournaments')
    .doc(id)
    .get()
    .then((doc) => {
      dispatch({
        type: GET_TOURNAMENT,
        detail: doc.data()
      })
    });
}

export const useCoupon = (firebase, id, token) => {
  return new Promise((resolve, reject) => {
    firebase.db
      .collection('coupons')
      .doc(id)
      .collection('list')
      .doc(token[0].id)
      .update({
        coupon: `${token[0].coupon}-`
      }).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });
  });
}

export const addParticipants = async (firebase, values, id, playersData, userId) => {
  const { teamName } = values;

  return new Promise((resolve, reject) => {
    firebase.db
      .collection('teams')
      .doc(id)
      .collection('participants')
      .add({
        idTournament: id,
        teamName,
        participants: playersData,
        teamCaptain: userId,
        totalPoints: 0
      })
      .then((doc) => {
        firebase.db
          .collection('tournaments')
          .doc(id)
          .update({
            id: id
          }).then(() => {
            resolve({ status: true });
          });
      }).catch(() => {
        reject(error);
        resolve({ status: false });
      });
  });
}

export const editParticipants = async (firebase, values, id, playersData, userId, teamId, dispatch) => {
  const { teamName } = values;
  return new Promise((resolve, reject) => {
    firebase.db
      .collection('teams')
      .doc(id)
      .collection('participants')
      .doc(teamId)
      .update({
        teamName,
        participants: playersData,
        teamCaptain: userId
      })
      .then((doc) => {
        resolve({ status: true });
      }).catch(() => {
        reject(error);
        resolve({ status: false });
      });
  });
}

export const getMatches = async (firebase, dispatch, idTournament, id, bestGames) => {
  const data = [];
  firebase.db
    .collection('matches')
    .doc(idTournament)
    .collection(id)
    .orderBy('totalPoints', 'desc')
    .limit(bestGames)
    .get()
    .then((docs) => {
      docs.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data()});
      });
      dispatch({
        type: GET_MATCHES,
        matches: data
      });
    })
} 

let lastVisible = 0;
let next = null;

export const listAllTournaments = async (firebase, dispatch, limit) => {
  if (next == null || next == undefined) {
    var first = firebase.db.collection('tournaments')
      .orderBy('startDate', 'desc')
      .limit(limit)
  }
  else if (next) {
    var first = next
  }
  return new Promise((resolve, reject) => {
    return first.get().then((documentSnapshots) => {
      const data = [];
      lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

      if (documentSnapshots.empty === false) {
        documentSnapshots.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data()});
        });
        dispatch({
          type: LIST_ALL_TOURNAMENTS,
          listAll: data
        });
        resolve(true);
        next = firebase.db.collection('tournaments')
          .orderBy('startDate', 'desc')
          .startAfter(lastVisible)
          .limit(limit)
      } else {
        resolve(false)
      }
    });
  });
}

const getParticipants = (firebase, id) => {
  return new Promise((resolve, reject) => {
    firebase.db
    .collection('users')
    .doc(id)
    .get()
    .then((part) => {
      resolve ({
        participants: { ...part.data() }
      })
    })
  })
}

let lastVisibleTeam = 0;
let nextTeam = null;

export const getTeam = async (firebase, dispatch, id, limit) => {
  if (nextTeam == null || nextTeam == undefined) {
    var firstTeam = firebase.db.collection('teams')
      .doc(id)
      .collection('participants')
      .orderBy('totalPoints', 'desc')
      .limit(limit)
  }
  else if (nextTeam) {
    var firstTeam = nextTeam
  }
  return new Promise((resolve, reject) => {
    return firstTeam.get().then(async (documentSnapshots) => {
      lastVisibleTeam = documentSnapshots.docs[documentSnapshots.docs.length-1];
    
      if (documentSnapshots.empty === false) {
        const data = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        return data.map(async(doc) => {
          return Promise.all(doc.participants.map(async(h) => {
            return await getParticipants(firebase, h.userId);  
          })).then(async(response) =>{
            if (response) {
              const part = response.map((d) => {
                return d.participants
              })
              dispatch({
                type: GET_TEAM,
                team: [{
                  id: doc.id,
                  idTournament: doc.idTournament,
                  teamCaptain: doc.teamCaptain,
                  teamName: doc.teamName,
                  totalPoints: doc.totalPoints,
                  participants: part
                }]
              });
              resolve(true);
              nextTeam = firebase.db.collection('teams')
                .doc(id)
                .collection('participants')
                .orderBy('totalPoints', 'desc')
                .startAfter(lastVisibleTeam)
                .limit(limit)
            }
          });
        });
        /*documentSnapshots.docChanges().forEach((change) => {
          if (change.oldIndex !== -1) {
            data.splice(change.oldIndex, 1);
          }
          if (change.newIndex !== -1) {
            data.splice(change.newIndex, 0, { id: change.doc.id, ...change.doc.data() });
          }
        });*/
      } else {
        resolve(false)
      }
    });
  });
}

