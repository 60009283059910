import { useContext } from 'react';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { FirebaseContext } from 'server';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { login } from 'context/session/actions';
import Loading from 'components/Loading';
import FloatingMessage from 'components/FloatingMessage';
import { schema } from './schema';

const LoginForm = () => {
  const firebase = useContext(FirebaseContext);
  const { t } = useTranslation('app');
  const history = useHistory();
  const [{ session: { loading }, commons: { message } }, dispatch] = useStateValue();

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const onSubmit = async values => {
    await login(dispatch, firebase, values, history);
  }
  
  return (
    <>
      <Form size="large" onSubmit={handleSubmit(onSubmit)}>
        {loading && <Loading />}

        <Segment padded textAlign="left">
          <Header as="h3" color="grey" textAlign="left">
            {t('session.enterEmailAndPassword')}
          </Header>
          <br />

          <Form.Field required error={!!errors.email}>
            <input
              type="email"
              name="email"
              autoComplete="current-email"
              placeholder={`${t('session.email')} *`}
              ref={register}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>

          <Form.Field required error={!!errors.password}>
            <input
              type="password"
              name="password"
              autoComplete="current-password"
              ref={register}
              placeholder={`${t('session.password')} *`}
            />
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
          <div className="text-right" style={{ marginBottom: 15 }}>
            <Link to="/reset-password">
              ¿Olvide mi contraseña?
            </Link>
          </div>

          <Button color="teal" fluid size="large">
            {t('session.login')}
          </Button>
        </Segment>
      </Form>

      <Message>
        {t('session.doNotHaveAUser')} <Link to="/signup">{t('session.signup')}</Link>
      </Message>
      {message && (
        <FloatingMessage
          message={message}
          isOpen={!!message}
          close={() => closeMessage(dispatch, null)}
        />
      )}
    </>
  )
}

export default LoginForm;