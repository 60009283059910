import { useState, useContext } from 'react';
import { Modal, Button, Header, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { useStateValue } from 'context/store';
import { FirebaseContext } from 'server';
import { useHistory } from 'react-router'
import { addParticipants, useCoupon, editParticipants } from 'context/tournaments/actions';
import SearchUsersPlatform from 'components/Search/SearchUsersPlatform';
import { modalMessage } from 'utils';
import { FORMAT, FORMAT_BRACKETS } from 'utils/constants';
import schema from './schema';
import style from './Participants.module.css';

export default function AddParticipants({
  open, setOpen, format, username, idTournament, platform, isPrivate, userId, teamName, teamId, playersEdit, tournamentFormat
}) {
  const [validate, setValidate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [playersData, setPlayersData] = useState([{ userId: userId, username: username, platform: platform }]);
  const firebase = useContext(FirebaseContext);
  const [, dispatch] = useStateValue();
  const history = useHistory()
  const { t } = useTranslation('app');

  const { register, handleSubmit, errors, control, clearErrors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const submittedForm = (mssg) => {
    setLoading(false);
    modalMessage(mssg, '', t('participants.continue')).then(() => {
      setPlayersData([{ userId: userId, username: username, platform: platform }]);
      setOpen(false);
      history.go(0);
    });
  }

  const totalPlayer = () => {
    const players = tournamentFormat === 'brackets' ? FORMAT_BRACKETS.filter(f => f.id === format) : FORMAT.filter(id => id.id === format);
    return players[0] ? [...Array(players[0].total).keys()] : [0];
  }

  useState(() => {
    if (playersEdit && playersEdit.length > 0) {
      setPlayersData([...playersEdit]);
    }
  }, [playersEdit, setPlayersData])

  const getPlayers = (index, player) => {
    if (playersData[index]) {
      setPlayersData(prevPlayer => {
        const updatePlayer = [...prevPlayer];
        updatePlayer[index] = player;
        return updatePlayer;
      });
    } else {
      setPlayersData(prevPlayers => [...prevPlayers, player]);
    }
  }

  const submit = async values => {
    setLoading(true);
    const repeatParticipants = [...new Map(values.participants.map(item => [item.username, item])).values()];

    if (repeatParticipants.length < values.participants.length) {
      setValidate(t('participants.youCannotHaveRepeatParticipants'));
      setLoading(false);
    } else if (isPrivate) {
      const token = [];
      firebase.db.collection('coupons')
        .doc(idTournament)
        .collection('list')
        .where('coupon', '==', values.coupon)
        .get()
        .then(async(docs) => {
          docs.forEach((doc) => {
            token.push({ id: doc.id, ...doc.data()});
          });
          if (token.length <= 0) {
            setValidate(t('participants.invalidCoupon'));
            setLoading(false);
          } else {
            setValidate('');
            const isCoupon = await useCoupon(firebase, idTournament, token);
            if (isCoupon) {
              const callback = await addParticipants(firebase, values, idTournament, playersData, userId);
              callback && callback.status ? submittedForm(t('participants.registeredTeam')) : setLoading(false);
            }
          }
      });
    }
    else {
      setValidate('');
      const playersE = playersData.map(d => {
        return {
          username: d.username,
          platform: d.platform,
          userId: d.userId
        }
      })
      if (teamId) {
        const callback = await editParticipants(firebase, values, idTournament, playersE, userId, teamId, dispatch);
        callback && callback.status ? submittedForm(t('participants.teamEditedSuccessfully')) : setLoading(false);
      } else {
        const callback = await addParticipants(firebase, values, idTournament, playersData, userId);
        callback && callback.status ? submittedForm(t('participants.registeredTeam')) : setLoading(false);
      }
    }
  }

  return (
    <Modal
      closeIcon={false}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header className={style.borderNone}>
        <Header textAlign="center" className={style.title}>
          {t('participants.registrationOfParticipants')}
          <Header.Subheader className={style.subtitle}>
            {`${t('participants.thisTournamentIs')} ${isPrivate ? t('participants.private') : t('participants.free')}, ${t('participants.participantRegistrationText')}`}
          </Header.Subheader>
        </Header>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form className={style.form} size="large" onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
            <Form.Group widths="equal">
              <Form.Field required>
                <input
                  type="text"
                  name="teamName"
                  placeholder={t('participants.nameOfTheTeam')}
                  ref={register}
                  defaultValue={teamName ? teamName : ''}
                />
                <ErrorMessage
                  errors={errors}
                  name="teamName"
                  render={({ message }) => <small className="red">{t(message)}</small>}
                />
              </Form.Field>
            </Form.Group>
            
            <Form.Group widths="equal">
              {totalPlayer().map((player, index) => {
                if (index === 0) {
                  return (
                    <Form.Field key={player} disabled={true}>
                      <input
                        type="text"
                        name={`participants[${index}].username`}
                        placeholder={`${t('participants.playerUsername')} ${index+1}`}
                        ref={register()}
                        defaultValue={username ? username : ''}
                      />
                    </Form.Field>
                  )
                }
                if (index === 1) {
                  return (
                    <Form.Field key={player}>
                      <SearchUsersPlatform
                        control={control}
                        name={`participants[${index}].username`}
                        defaultValue={playersEdit && playersEdit[index] ? playersEdit[index].username : ''}
                        placeholder={`${t('participants.playerUsername')} ${index+1}`}
                        clearErrors={clearErrors}
                        required
                        getPlayers={getPlayers}
                        index={index}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`participants[${index}].username`}
                        render={({ message }) => <small className="red">{t(message)}</small>}
                      />
                    </Form.Field>
                  )
                }
              })}
            </Form.Group>

            {totalPlayer().length >= 2 && (
              <Form.Group widths="equal">
                {totalPlayer().map((player, index) => {
                  if (index >= 2) {
                    return (
                      <Form.Field key={player}>
                        <SearchUsersPlatform
                          control={control}
                          name={`participants[${index}].username`}
                          defaultValue={playersEdit && playersEdit[index] ? playersEdit[index].username : ''}
                          placeholder={`${t('participants.playerUsername')} ${index+1}`}
                          clearErrors={clearErrors}
                          required
                          getPlayers={getPlayers}
                          index={index}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`participants[${index}].username`}
                          render={({ message }) => <small className="red">{t(message)}</small>}
                        />
                      </Form.Field>
                    )
                  }
                })}
              </Form.Group>
            )}
            {isPrivate && (
              <Form.Group widths="equal">
                <Form.Field required>
                  <input
                    type="text"
                    name="coupon"
                    placeholder={t('participants.coupon')}
                    ref={register}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="coupon"
                    render={({ message }) => <small className="red">{t(message)}</small>}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <div className="text-right">
              <p className="red text-center">{validate}</p>
              <br />
              <Button
                content={t('participants.cancel')}
                type="button"
                disabled={loading && true}
                style={{ padding: '14px 26px', marginRight: 17 }}
                onClick={() => setOpen(false)}
              />
              <Button
                loading={loading && true}
                content={t('participants.finishRegistration')}
                type="submit"
                className="btn-primary btn-red"
                disabled={loading && true}
              />
            </div>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}