import { useState, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Tabs from 'react-simply-tabs';
import voucherCodes from 'voucher-code-generator';
import { FirebaseContext } from 'server';
import { tournamentForm, newTournament } from 'context/tournaments/actions';
import Loading from 'components/Loading';
import { modalMessage } from 'utils';

import TabInformation from './TabsForm/TabInformation';
import TabPoints from './TabsForm/TabPoints';
import TabAwards from './TabsForm/TabAwards';
import TabParticipants from './TabsForm/TabParticipants';
import { useStateValue } from 'context/store';
import style from './FormTournament.module.css';

function FormTournaments({ history }) {
  const { t } = useTranslation('app');
  const firebase = useContext(FirebaseContext);
  const [{tournaments: { form, loading }}, dispatch] = useStateValue();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const submittedForm = (isPrivate) => {
    modalMessage(t('tournaments.form.successfullyCreatedTournament'), `${isPrivate ? t('tournaments.form.successfullyPrivateCreated') : ''}`, t('tournaments.form.continue')).then(() => {
      history.push('/')
    });
  }

  const onSubmit = async values => {
    tournamentForm(dispatch, values);
    if (activeTabIndex === 3) {
      modalMessage(t('tournaments.form.areYouSureToCreateThisTournament'), t('tournaments.form.onceCreatedTheTournamentCannotBeModified'), t('tournaments.form.continue'), true, 'Cancelar').then(async({ isConfirmed }) => {
        if (isConfirmed) {
          const callback = await newTournament(dispatch, firebase, { ...form, ...values }, firebase.auth.currentUser.uid, voucherCodes);
          callback.status && submittedForm(values.isPrivate);
        }
      });
    } else {
      setActiveTabIndex(activeTabIndex + 1);
    }
  }

  return (
    <div className={style.containerForm}>
      {loading && <Loading />}
      <Tabs
        activeTabIndex={activeTabIndex}
        onRequestChange={setActiveTabIndex}
        controls={[
          <Button type="button" className={style.menuTabs} basic disabled active={activeTabIndex === 0 && true}>
            {t('tournaments.basicInformation')}
          </Button>,
          <Button type="button" className={style.menuTabs} basic disabled active={activeTabIndex === 1 && true}>
            {t('tournaments.setting')}
          </Button>,
          <Button type="button" className={style.menuTabs} basic disabled active={activeTabIndex === 2 && true}>
            {t('tournaments.awards')}
          </Button>,
          <Button type="button" className={style.menuTabs} basic disabled active={activeTabIndex === 3 && true}>
            {t('tournaments.participants')}
          </Button>
        ]}
      >
        <div>
          <TabInformation
            onSubmit={onSubmit}
          />
        </div>
        <div>
          <TabPoints
            onSubmit={onSubmit}
            setActiveTabIndex={setActiveTabIndex}
            activeTabIndex={activeTabIndex}
            formData={form && form}
          />
        </div>
        <div>
          <TabAwards
            onSubmit={onSubmit}
            gameDuration={form ? form.gameDuration : 1}
            setActiveTabIndex={setActiveTabIndex}
            activeTabIndex={activeTabIndex}
          />
        </div>
        <div>
          <TabParticipants
            setActiveTabIndex={setActiveTabIndex}
            activeTabIndex={activeTabIndex}
            onSubmit={onSubmit}
          />
        </div>
      </Tabs>
    </div>
  )
}

export default withRouter(FormTournaments);