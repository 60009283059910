import sessionReducer from './session/reducer';
import commonsReducer from './commons/reducer';
import playerDataReducer from './playerData/reducer';
import tournamentsReducer from './tournaments/reducer';

export const indexReducer = ({ session, commons, playerData, tournaments }, action) => {
  return {
    session: sessionReducer(session, action),
    commons: commonsReducer(commons, action),
    playerData: playerDataReducer(playerData, action),
    tournaments: tournamentsReducer(tournaments, action)
  }
}
