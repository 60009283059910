const initialState = {
  commons: {
    message: null,
    userData: null,
    loadingUser: false
  },
  session: {
    user: null,
    loading: false,
    isLogin: false
  },
  playerData: {
    loadingPlayer: false,
    data: null,
    messagePlayer: null
  },
  tournaments: {
    form: null,
    loading: false,
    list: null,
    listAll: null,
    specialList: null,
    detail: null,
    team: null,
    matches: []
  }
}

export default initialState;