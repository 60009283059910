import { LOADING_PLAYER_DATA, PLAYER_DATA, MESSAGE_PLAYER_DATA} from './constants';

const playerDataReducer = (state, action) => {
  switch (action.type) {
    case PLAYER_DATA:
      return {
        ...state,
        data: action.data
      }
    case LOADING_PLAYER_DATA:
      return {
        ...state,
        loadingPlayer: action.loadingPlayer
      }
    case MESSAGE_PLAYER_DATA:
      return {
        ...state,
        messagePlayer: action.messagePlayer
      }
    default:
      return state
  }
}

export default playerDataReducer;