import style from './CardDetail.module.css';

export default function CardDetail({
  icon,
  text,
  pointsCard,
  maxKd,
  maxGroupKd
}) {
  if (pointsCard) {
    return (
      <div className={style.cardContainer}>
        <p className={style.textTop}>
          MAX. KD PERSONAL
          <br />
          <b>{maxKd}</b>
        </p>
        <p className={style.textBottom}>
          <b>{maxGroupKd}</b>
          <br />
          MAX. KD GRUPAL
        </p>
      </div>
    )
  }
  return (
    <div className={style.cardContainer}>
      <img src={`/images/icons/${icon}`} />
      <p className={style.text}>
        {text}
      </p>
    </div>
  )
}