import { SIGNUP, LOGIN, LOGOUT } from './constants';

const sessionReducer = (state, action) => {
  switch (action.type) {
    case SIGNUP:
      action.session.user && localStorage.setItem('isLogin', action.session.isLogin);
      return {
        ...state,
        ...action.session
      }
    case LOGIN:
      action.session.user && localStorage.setItem('isLogin', action.session.isLogin);
      return {
        ...state,
        ...action.session
      }
    case LOGOUT:
      return {
        ...state,
        ...action.session
      }
    default:
      return state
  }
}

export default sessionReducer;
