import Slider from 'react-slick';
import Testimony from './Testimony';

const testimonials = [
  {
    id: '1',
    name: 'GameFather92',
    position: 'father.founder',
    image: '/images/gamefather92-streamer.jpg',
    description: 'father.description',
    socialLink: 'https://fb.gg/GameFather92',
  },
  {
    id: '2',
    name: 'Disgrazze',
    position: 'dis.founder',
    image: '/images/disgrazze-streamer.jpg',
    description: 'dis.description',
    socialLink: 'https://fb.gg/Disgrazze/'
  }
]

export default function Prefooter() {

  const settings = {
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplay: true
  };

  return (
    <Slider {...settings}>
      {testimonials.map(testimony => (
        <Testimony key={testimony.id} {...testimony} />
      ))}
    </Slider>
  )
}