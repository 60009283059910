import { Grid, Header, Icon } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import Language from 'components/Language';
import Warning from 'components/Layouts/Layout/Warning';
import style from './layoutOut.module.css';

const LayoutOut = ({
  children,
  title,
  icon
}) => {
  const isLogin = localStorage.getItem('isLogin');

  if (isLogin) return <Redirect exact to="/app" />

  return (
    <>
    <Warning />
    <Grid 
      textAlign="center"
      verticalAlign="middle"
      container
      style={{ padding: '30px 0',  minHeight: 'calc(100vh - 70px)' }}
    >
      <Grid.Column className={style.content}>
        <Header as="h2">
          <Icon
            name={icon}
            color="teal"
          />
          <Header.Content>
            {title}
          </Header.Content>
        </Header>
        <div className={style.language}>
          <Language />
        </div>
        {children}
      </Grid.Column>
    </Grid>
    </>
  );
}

export default LayoutOut;