import { Grid } from 'semantic-ui-react';

export default function SocialProfile({ social }) {
  return (
    <Grid columns="equal" style={{ padding: '35px 0 20px'}} stackable>
      <Grid.Row>
        {social.fbSocial && (
          <Grid.Column>
            <a href={social.fbSocial} target="_blank">
              <img src="/images/social/facebook.svg" />
            </a>
          </Grid.Column>
        )}
        {social.twitchSocial && (
          <Grid.Column>
            <a href={social.twitchSocial} target="_blank">
              <img src="/images/social/twitch.svg" />
            </a>
          </Grid.Column>
        )}
        {social.ytSocial && (
          <Grid.Column>
            <a href={social.ytSocial} target="_blank">
              <img src="/images/social/youtube.svg" />
            </a>
          </Grid.Column>
        )}
        {social.otherSocial && (
          <Grid.Column>
            <a href={social.otherSocial} target="_blank">
              <img src="/images/social/other.svg" />
            </a>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  )
}