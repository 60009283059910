import React, { createContext, useContext, useReducer } from 'react';
import initialState from './initialState';

export const AppContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <AppContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </AppContext.Provider>
)

export const useStateValue = () => useContext(AppContext);
const useStore = () => useContext(AppContext)[0];
const useDispatch = () => useContext(AppContext)[1];

export { useStore, useDispatch };