import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import style from './Error.module.css';

const Error = () => {
  const { t, i18n } = useTranslation('app');
  return (
    <Grid verticalAlign="middle" textAlign="center" className={style.containerError}>
      <Grid.Column>
        <h1>{t('error.pageNotFound')} 😢 </h1>
        <Link to="/" className={`button ${style.button}`}>
          {t('error.goToHome')}
        </Link>
      </Grid.Column>
    </Grid>
  );
}

export default Error;
