import { Card, Image, Button, Grid } from 'semantic-ui-react';
import { getImageDefault } from 'utils';
import { Link } from 'react-router-dom';
import { GAMES } from 'utils/constants';
import style from './CardTournamentsUpcoming.module.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/es';

export default function CardTournamentsUpcoming({
  id, image, game, name, organizers, startDate, awards, isPrivate
}) {
  const { t } = useTranslation('app');
  const lang = useTranslation().i18n.language;
  moment.locale(lang);
  const dateTournament = moment(startDate.toDate())
  const dateNow = moment(Date.now());
  const percent = dateNow.diff(dateTournament, "hours") * -0.100;
  const urlName = name.replace(/[^A-Z0-9]/ig, "-");

  return (
    <Link to={`/tournaments/${id}/${urlName}`} className={style.cardLink}>
      <div className={style.card}>
        <div className={style.cardUpcoming}>
          <Image
            src={image ? image : getImageDefault(GAMES, game)}
            wrapped
            fluid
            className={style.imageCard}
          />
          <span className={style.platform}>
            {GAMES.filter(gm => gm.id === game)[0].name}
          </span>
        </div>
        <Card.Content style={{ padding: '30px 20px 20px' }}>
          <Card.Header className={style.name}>
            {name}
          </Card.Header>
          <Card.Meta>
            <p className={style.textGreen}>
              {dateTournament > dateNow ? t('next.start') : t('next.started') } {dateTournament.from(dateNow)}
            </p>
            <div className="ui progress tiny">
              <div className="bar" style={{width: `${100 - Math.round(percent)}%`, backgroundColor: '#2EB4CC', maxWidth: '100%' }}>
              </div>
            </div>
          </Card.Meta>
          <Card.Description className={style.descriptionText}>
            {awards && (
              <>
                <p>{t('tournament.theFirstPlace')}: <b>{awards.winner}</b></p>
                {awards.top2 && <p>{t('tournament.secondPlace')}: <b>{awards.top2}</b></p>}
              </>
            )}
          </Card.Description>
          <Card.Meta>
            <span className={style.organizer}>
              {t('tournament.organize')}:
              <b className={style.textRed}>
                {organizers && organizers.length > 0 &&
                  organizers.map(org => (
                    org.organizerName ? `${org.organizerName}, ` : '-'
                  ))
                }
              </b>
            </span>
          </Card.Meta>
          <div className={style.footerCard}>
            <Grid verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column textAlign="left">
                  <span className={style.labelPrice}>
                    {t('tournament.price')}
                  </span>
                  <p className={style.price}>
                    {isPrivate ? t('tournament.conditions'): t('tournament.free')}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Button to="#" className="btn-primary">
                    {t('tournament.getIn')}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Card.Content>
      </div>
    </Link>
  )
}