import { useState } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import schema from './schemaAwards';
import WYSIWYGEditor from 'components/Editor';

export default function TabAwards({
  onSubmit,
  gameDuration,
  activeTabIndex,
  setActiveTabIndex
}) {
  const { t } = useTranslation('app');
  const [editorState, setEditorState] = useState(
    null
  );
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      desc: ''
    },
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const bestGameOptions = (type) => {
    switch(type) {
      case 1:
      case 2:
      case 3:
        return [...Array(2).keys()]
      case 4:
        return [...Array(3).keys()]
      case 5:
        return [...Array(4).keys()]
      case 6:
        return [...Array(5).keys()]
      case 7:
        return [...Array(6).keys()]
      case 8:
        return [...Array(7).keys()]
      case 9:
        return [...Array(8).keys()]
      default:
        return [...Array(9).keys()]
    }
  }

  return (
    <Form size="large" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
      <Segment textAlign="left" color="teal" stacked padded="very">

        <Form.Group widths="2">
          <Form.Field required>
            <label>{t('tournaments.form.bestGames')}</label>
            <Controller
              name="bestGames"
              defaultValue={1}
              control={control}
              as={
                <select>
                  {gameDuration && (
                    bestGameOptions(parseInt(gameDuration)).map(game => (
                      <option key={game} value={game+1}>{game+1}</option>
                    )
                  ))}
                </select>
              }
            />
            <ErrorMessage
              errors={errors}
              name="bestGames"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field>
            <label>{t('tournaments.form.description')}</label>
            <Controller
              name="description"
              control={control}
              render={({ onChange }) => (
                <WYSIWYGEditor onChange={onChange} />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="description"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>{t('tournaments.form.rules')}</label>
            <Controller
              name="rules"
              control={control}
              render={({ onChange }) => (
                <WYSIWYGEditor onChange={onChange} />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="rules"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
        </Form.Group>

        <h3 className="text-center">{t('tournaments.form.awards.title')}</h3>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('tournaments.form.awards.winner')}</label>
            <input
              name="awards.winner"
              placeholder={t('tournaments.form.awards.winnerAward')}
              ref={register}
            />
            <ErrorMessage
              errors={errors}
              name="awards.winner"
              render={({ message }) => <small className="red">{t(message)}</small>}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('tournaments.form.awards.top2')}</label>
            <input
              name="awards.top2"
              placeholder={t('tournaments.form.awards.top2Award')}
              ref={register}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('tournaments.form.awards.top3')}</label>
            <input
              name="awards.top3"
              placeholder={t('tournaments.form.awards.top3Award')}
              ref={register}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('tournaments.form.awards.top4')}</label>
            <input
              name="awards.top4"
              placeholder={t('tournaments.form.awards.top4Award')}
              ref={register}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('tournaments.form.awards.top5')}</label>
            <input
              name="awards.top5"
              placeholder={t('tournaments.form.awards.top5Award')}
              ref={register}
            />
          </Form.Field>
        </Form.Group>

        <br />
        <div className="text-center">
          <Button
            type="button"
            color="teal"
            content={t('tournaments.form.back')}
            icon="arrow left"
            size="large"
            labelPosition="left"
            onClick={() => setActiveTabIndex(activeTabIndex - 1)}
          />
          <Button
            color="teal"
            content={t('tournaments.form.next')}
            icon="arrow right"
            size="large"
            labelPosition="right"
          />
        </div>
      </Segment>
    </Form>
  )
}