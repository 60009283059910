import SwalAlert from 'sweetalert2';

export const modalMessage = (
  title,
  text,
  nameButton = 'Ok',
  isCancelButton = false,
  nameButtonCancel
) => {
  return SwalAlert.fire({
    title: title,
    text: text,
    showCancelButton: isCancelButton,
    confirmButtonText: nameButton,
    cancelButtonText: nameButtonCancel,
    confirmButtonColor: '#00b5ad',
    cancelButtonColor: '#757575',
    allowEscapeKey: false,
    allowOutsideClick: false,
    width: 600,
    padding: '3em',
  });
};

export const dateFormat = (date) => {
  return date.toDate().toLocaleDateString();
}

export const timeFormat = (date) => {
  return date.toDate().toLocaleTimeString();
}

export const sumHours = (date, hour) => {
  const dateT = new Date(date.seconds * 1000);
  dateT.setHours( dateT.getHours() + hour );
  return dateT.getTime();
}

export const getImageDefault = (games, game) => {
  const img = games.filter(g => g.id === game);
  return img[0].image;
}